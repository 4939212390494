<template>
	<div>
		<div class="serviceItems" v-show="windowWidth>=1200">
			<div style="text-align: center;">
				<!-- <div class="si_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">service</div> -->
				<div class="si_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('homeAbout.title4')}}</div>
			</div>
			<div class="theSwiper wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0">
				<swiper :options="swiperOption1">
					<swiper-slide v-for="(item,index) in $t('homeAbout.serviceItems')" :key="index">
						<div class="swiper_slide_div wow fadeInUp" style="height: 100%;" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0" @click="jump">
							<div class="mask"></div>
							<div style="padding: 20px;z-index: 15;position: relative;">
								<div class="circle_icon">
									<div class="dot"></div>
									<div class="dot2"></div>
									<el-image class="do_icon" :src="item.image" :fit="'cover'"></el-image>
								</div>
								<div class="sw_text1">{{item.title}}</div>
								<div class="sw_text2">{{item.content}}</div>
								<!-- <div class="sw_text3">MORE+</div> -->
							</div>
						</div>
					</swiper-slide>
		
					<!-- <div class="swiper-pagination" slot="pagination"></div> -->
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</swiper>
			</div>
		</div>
		
		<div class="serviceItems" v-show="windowWidth<=1199">
			<div style="text-align: center;">
				<!-- <div class="si_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">service</div> -->
				<div class="si_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('homeAbout.title4')}}</div>
			</div>
			<div class="theSwiper wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0">
				<swiper :options="swiperOptionTwo">
					<swiper-slide v-for="(item,index) in $t('homeAbout.serviceItems')" :key="index">
						<div class="swiper_slide_div wow fadeInUp" style="height: 100%;" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0" @click="jump">
							<div class="mask"></div>
							<div style="padding: 20px;z-index: 15;position: relative;">
								<div class="circle_icon">
									<div class="dot"></div>
									<div class="dot2"></div>
									<el-image class="do_icon" :src="item.image" :fit="'cover'"></el-image>
								</div>
								<div class="sw_text1">{{item.title}}</div>
								<div class="sw_text2">{{item.content}}</div>
								<!-- <div class="sw_text3">MORE+</div> -->
							</div>
						</div>
					</swiper-slide>
		
					<!-- <div class="swiper-pagination" slot="pagination"></div> -->
					<div class="swiper-button-prev" slot="button-prev"></div>
					<div class="swiper-button-next" slot="button-next"></div>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	require("swiper/dist/css/swiper.css");
	import WOW from "wowjs";
	export default{
		components: {
			swiper,
			swiperSlide,
		},
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return{
				swiperOption1: {
					loop: false, //首尾循环
					//不想自动滚动，只需注释掉autoplay属性即可
					// autoplay: {
					// 	delay: 600,
					// 	stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
					// 	disableOnInteraction: false, //true：交互后，不继续滚动
					// },
					// centeredSlides: true, //true:当前板块居中
					slidesPerView: 5, //页面中的板块数量
				
					freeMode: false, //true:拖到哪就是哪
					spaceBetween: 30, //板块间隔
					grabCursor: false, //true：鼠标为抓手
					// slidesPerGroup: 3, //多少个板块为1组。
					// 显示分页
					// pagination: {
					// 	el: ".swiper-pagination",
					// 	clickable: true, //true：点击底部小圆点可以跳转页面
					// 	type: "fraction", //当前页显示 1/7
					// },
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					observer:true,//修改swiper自己或子元素时，自动初始化swiper
					observeParents:true//修改swiper的父元素时，自动初始化swiper
				},
				swiperOptionTwo: {
					loop: false, //首尾循环
					//不想自动滚动，只需注释掉autoplay属性即可
					// autoplay: {
					// 	delay: 600,
					// 	stopOnLastSlide: false, //true表示滚动到最后一页后停止滚动
					// 	disableOnInteraction: false, //true：交互后，不继续滚动
					// },
					// centeredSlides: true, //true:当前板块居中
					slidesPerView: 1, //页面中的板块数量
				
					freeMode: false, //true:拖到哪就是哪
					spaceBetween: 30, //板块间隔
					grabCursor: false, //true：鼠标为抓手
					// slidesPerGroup: 3, //多少个板块为1组。
					// 显示分页
					// pagination: {
					// 	el: ".swiper-pagination",
					// 	clickable: true, //true：点击底部小圆点可以跳转页面
					// 	type: "fraction", //当前页显示 1/7
					// },
					// 设置点击箭头
					navigation: {
						nextEl: ".swiper-button-next",
						prevEl: ".swiper-button-prev",
					},
					observer:true,//修改swiper自己或子元素时，自动初始化swiper
					observeParents:true//修改swiper的父元素时，自动初始化swiper
				},
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
		},
		
		watch:{
			windowWidth(val){
				if(val>=1200){
					this.swiperOption.slidesPerView=5
				}else{
					this.swiperOption.slidesPerView=1
				}
			}
		},
		
		computed:{
			
		},
		
		methods:{
			//------------------路由跳转-------------------------
			jump:function(){
				this.$router.push({
					path: '/serviceItems',
					query:{
						anchorPointIndex:0
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	@font-face {
		font-family: 'BigJohn';
		src: url('../../../assets/big-john-woff2-2.ttf') format('truetype');
	}
	
	@font-face {
		font-family: 'zhenyan';
		src: url('../../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}
	
	
	@media only screen and (min-width:1920px) {
		.serviceItems {
			background-size: cover;
			backgorund-position: center center;
			padding: 80px 200px;
			position: relative;
			z-index: 9;
			background-image: url(http://static601.yun300.cn/img/bg_serv01.jpg?tenantId=252768&viewType=1&v=1678089441000);
		
			.si_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.si_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		.theSwiper {
			width: 100%;
			margin-top: 50px;
		
			.swiper-container {
				position: relative;
				// height: 600px;
		
				.swiper-slide {
					width: calc((100% - 12vw) / 5);
					// line-height: 600px;
					color: rgb(255, 255, 255);
					background: rgba(255,255,255,0.4);
					cursor: pointer;
					font-size: 16px;
					text-align: center;
					z-index: 12;
					position: relative;
					
				}
			}
		}
		
		
		// .swiper-container:hover .swiper-slide{
		// 	background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
		// }
		
		.circle_icon{
			width: 100%;
			height: 120px;
			padding-top: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.swiper_slide_div{
			position: relative;
			width: 100%;
			height: 100%;
		
		}
		
		.mask{
			width: 100%;
			height: 100%;
			content: '';
			// line-height: 600px;
			z-index: 13;
			top: 0;
			left: 0;
			position: absolute;
			background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
			opacity: 0;
		}
		
		
		.swiper_slide_div:hover .mask{
			transition: all 0.5s;
			opacity: 1;
		}
		
		.sw_text1{
			text-align: center;
			color: #333;
			font-size: 14px;
			margin-top: 40px;
		}
		
		.sw_text2{
			text-align: center;
			color: #333;
			margin: 40px 40px 0 40px;
			line-height: 30px;
			font-size: 14px;
			height: 150px;
			margin-bottom: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		
		.sw_text3{
			text-align: center;
			color: #333;
			line-height: 30px;
			font-size: 14px;
			padding-bottom: 60px;
			margin-top: 10px;
		}
		
		.swiper_slide_div:hover .sw_text1{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text2{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text3{
			color: #fff;
			transition: all 0.5s;
		}
		
		.dot {
			width: 110px;
			height: 110px;
			background: rgba(255,255,255,0.6);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 2s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.dot2 {
			width: 90px;
			height: 90px;
			position: absolute;
			margin: 0 auto;
			background: rgba(255,255,255);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple2;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 1.6s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.do_icon{
			width: 40px;
			height: 40px;
			position: absolute;
			margin: 0 auto;
		}
		
		@keyframes ripple {
			0% {
		
				width: 100px;
				height: 100px;
			}
		
			100% {
		
				width: 120px;
				height: 120px;
			}
		}
		
		@keyframes ripple2 {
			0% {
		
				width: 80px;
				height: 80px;
			}
		
			100% {
		
				width: 100px;
				height: 100px;
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.serviceItems {
			background-size: cover;
			backgorund-position: center center;
			padding: 80px 200px;
			position: relative;
			z-index: 9;
			background-image: url(http://static601.yun300.cn/img/bg_serv01.jpg?tenantId=252768&viewType=1&v=1678089441000);
		
			.si_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.si_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		.theSwiper {
			width: 100%;
			margin-top: 50px;
		
			.swiper-container {
				position: relative;
				// height: 600px;
		
				.swiper-slide {
					width: calc((100% - 12vw) / 5);
					// line-height: 600px;
					color: rgb(255, 255, 255);
					background: rgba(255,255,255,0.4);
					cursor: pointer;
					font-size: 16px;
					text-align: center;
					z-index: 12;
					position: relative;
					
				}
			}
		}
		
		
		// .swiper-container:hover .swiper-slide{
		// 	background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
		// }
		
		.circle_icon{
			width: 100%;
			height: 120px;
			padding-top: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.swiper_slide_div{
			position: relative;
			width: 100%;
			height: 100%;
		
		}
		
		.mask{
			width: 100%;
			height: 100%;
			content: '';
			// line-height: 600px;
			z-index: 13;
			top: 0;
			left: 0;
			position: absolute;
			background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
			opacity: 0;
		}
		
		
		.swiper_slide_div:hover .mask{
			transition: all 0.5s;
			opacity: 1;
		}
		
		.sw_text1{
			text-align: center;
			color: #333;
			font-size: 14px;
			margin-top: 40px;
		}
		
		.sw_text2{
			text-align: center;
			color: #333;
			margin: 40px 40px 0 40px;
			line-height: 30px;
			font-size: 14px;
			height: 150px;
			margin-bottom: 60px;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		
		.sw_text3{
			text-align: center;
			color: #333;
			line-height: 30px;
			font-size: 14px;
			padding-bottom: 60px;
			margin-top: 10px;
		}
		
		.swiper_slide_div:hover .sw_text1{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text2{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text3{
			color: #fff;
			transition: all 0.5s;
		}
		
		.dot {
			width: 110px;
			height: 110px;
			background: rgba(255,255,255,0.6);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 2s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.dot2 {
			width: 90px;
			height: 90px;
			position: absolute;
			margin: 0 auto;
			background: rgba(255,255,255);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple2;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 1.6s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.do_icon{
			width: 40px;
			height: 40px;
			position: absolute;
			margin: 0 auto;
		}
		
		@keyframes ripple {
			0% {
		
				width: 100px;
				height: 100px;
			}
		
			100% {
		
				width: 120px;
				height: 120px;
			}
		}
		
		@keyframes ripple2 {
			0% {
		
				width: 80px;
				height: 80px;
			}
		
			100% {
		
				width: 100px;
				height: 100px;
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.serviceItems {
			background-size: cover;
			backgorund-position: center center;
			padding: 80px 100px;
			position: relative;
			z-index: 9;
			background-image: url(http://static601.yun300.cn/img/bg_serv01.jpg?tenantId=252768&viewType=1&v=1678089441000);
		
			.si_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.si_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		.theSwiper {
			width: 100%;
			margin-top: 50px;
		
			.swiper-container {
				position: relative;
				// height: 600px;
		
				.swiper-slide {
					width: calc((100% - 12vw) / 5);
					// line-height: 600px;
					color: rgb(255, 255, 255);
					background: rgba(255,255,255,0.4);
					cursor: pointer;
					font-size: 16px;
					text-align: center;
					z-index: 12;
					position: relative;
					
				}
			}
		}
		
		
		// .swiper-container:hover .swiper-slide{
		// 	background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
		// }
		
		.circle_icon{
			width: 100%;
			height: 120px;
			padding-top: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.swiper_slide_div{
			position: relative;
			width: 100%;
			height: 100%;
		
		}
		
		.mask{
			width: 100%;
			height: 100%;
			content: '';
			// line-height: 600px;
			z-index: 13;
			top: 0;
			left: 0;
			position: absolute;
			background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
			opacity: 0;
		}
		
		
		.swiper_slide_div:hover .mask{
			transition: all 0.5s;
			opacity: 1;
		}
		
		.sw_text1{
			text-align: center;
			color: #333;
			font-size: 14px;
			margin-top: 40px;
		}
		
		.sw_text2{
			text-align: center;
			color: #333;
			margin: 40px 40px 0 40px;
			line-height: 30px;
			font-size: 14px;
			height: 150px;
			margin-bottom: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		
		.sw_text3{
			text-align: center;
			color: #333;
			line-height: 30px;
			font-size: 14px;
			padding-bottom: 60px;
			margin-top: 10px;
		}
		
		.swiper_slide_div:hover .sw_text1{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text2{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text3{
			color: #fff;
			transition: all 0.5s;
		}
		
		.dot {
			width: 110px;
			height: 110px;
			background: rgba(255,255,255,0.6);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 2s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.dot2 {
			width: 90px;
			height: 90px;
			position: absolute;
			margin: 0 auto;
			background: rgba(255,255,255);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple2;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 1.6s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.do_icon{
			width: 40px;
			height: 40px;
			position: absolute;
			margin: 0 auto;
		}
		
		@keyframes ripple {
			0% {
		
				width: 100px;
				height: 100px;
			}
		
			100% {
		
				width: 120px;
				height: 120px;
			}
		}
		
		@keyframes ripple2 {
			0% {
		
				width: 80px;
				height: 80px;
			}
		
			100% {
		
				width: 100px;
				height: 100px;
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.serviceItems {
			background-size: cover;
			backgorund-position: center center;
			padding: 80px 60px;
			position: relative;
			z-index: 9;
			background-image: url(http://static601.yun300.cn/img/bg_serv01.jpg?tenantId=252768&viewType=1&v=1678089441000);
		
			.si_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.si_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		.theSwiper {
			width: 100%;
			margin-top: 50px;
		
			.swiper-container {
				position: relative;
				// height: 600px;
		
				.swiper-slide {
					width: calc((100% - 12vw) / 5);
					// line-height: 600px;
					color: rgb(255, 255, 255);
					background: rgba(255,255,255,0.4);
					cursor: pointer;
					font-size: 16px;
					text-align: center;
					z-index: 12;
					position: relative;
					
				}
			}
		}
		
		
		// .swiper-container:hover .swiper-slide{
		// 	background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
		// }
		
		.circle_icon{
			width: 100%;
			height: 120px;
			padding-top: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		
		.swiper_slide_div{
			position: relative;
			width: 100%;
			height: 100%;
		
		}
		
		.mask{
			width: 100%;
			height: 100%;
			content: '';
			// line-height: 600px;
			z-index: 13;
			top: 0;
			left: 0;
			position: absolute;
			background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
			opacity: 0;
		}
		
		
		.swiper_slide_div:hover .mask{
			transition: all 0.5s;
			opacity: 1;
		}
		
		.sw_text1{
			text-align: center;
			color: #333;
			font-size: 14px;
			margin-top: 40px;
		}
		
		.sw_text2{
			text-align: center;
			color: #333;
			margin: 40px 40px 0 40px;
			line-height: 30px;
			font-size: 14px;
			height: 150px;
			margin-bottom: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
		}
		
		.sw_text3{
			text-align: center;
			color: #333;
			line-height: 30px;
			font-size: 14px;
			padding-bottom: 60px;
			margin-top: 10px;
		}
		
		.swiper_slide_div:hover .sw_text1{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text2{
			color: #fff;
			transition: all 0.5s;
		}
		
		.swiper_slide_div:hover .sw_text3{
			color: #fff;
			transition: all 0.5s;
		}
		
		.dot {
			width: 110px;
			height: 110px;
			background: rgba(255,255,255,0.6);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 2s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.dot2 {
			width: 90px;
			height: 90px;
			position: absolute;
			margin: 0 auto;
			background: rgba(255,255,255);
			border-radius: 50%;
			// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
			-webkit-animation-name: ripple2;
			/*动画属性名，也就是我们前面keyframes定义的动画名*/
			-webkit-animation-duration: 1.6s;
			/*动画持续时间*/
			-webkit-animation-timing-function: ease;
			/*动画频率，和transition-timing-function是一样的*/
			-webkit-animation-delay: 0s;
			/*动画延迟时间*/
			-webkit-animation-iteration-count: infinite;
			/*定义循环资料，infinite为无限次*/
			-webkit-animation-direction: alternate;
			/*定义动画方式*/
		}
		
		.do_icon{
			width: 40px;
			height: 40px;
			position: absolute;
			margin: 0 auto;
		}
		
		@keyframes ripple {
			0% {
		
				width: 100px;
				height: 100px;
			}
		
			100% {
		
				width: 120px;
				height: 120px;
			}
		}
		
		@keyframes ripple2 {
			0% {
		
				width: 80px;
				height: 80px;
			}
		
			100% {
		
				width: 100px;
				height: 100px;
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.serviceItems {
				background-size: cover;
				backgorund-position: center center;
				padding: 80px 60px;
				position: relative;
				z-index: 9;
				background-image: url(http://static601.yun300.cn/img/bg_serv01.jpg?tenantId=252768&viewType=1&v=1678089441000);
			
				.si_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
			
				.si_title_cn {
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
			}
			
			.theSwiper {
				width: 100%;
				margin-top: 50px;
			
				.swiper-container {
					position: relative;
					// height: 600px;
			
					.swiper-slide {
						width: calc((100% - 12vw) / 5);
						// line-height: 600px;
						color: rgb(255, 255, 255);
						background: rgba(255,255,255,0.4);
						cursor: pointer;
						font-size: 16px;
						text-align: center;
						z-index: 12;
						position: relative;
						
					}
				}
			}
			
			
			// .swiper-container:hover .swiper-slide{
			// 	background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
			// }
			
			.circle_icon{
				width: 100%;
				height: 120px;
				padding-top: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			.swiper_slide_div{
				position: relative;
				width: 100%;
				height: 100%;
			
			}
			
			.mask{
				width: 100%;
				height: 100%;
				content: '';
				// line-height: 600px;
				z-index: 13;
				top: 0;
				left: 0;
				position: absolute;
				background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
				opacity: 0;
			}
			
			
			.swiper_slide_div:hover .mask{
				transition: all 0.5s;
				opacity: 1;
			}
			
			.sw_text1{
				text-align: center;
				color: #333;
				font-size: 14px;
				margin-top: 40px;
			}
			
			.sw_text2{
				text-align: center;
				color: #333;
				margin: 40px 40px 0 40px;
				line-height: 30px;
				font-size: 14px;
				height: 150px;
				margin-bottom: 60px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
			
			.sw_text3{
				text-align: center;
				color: #333;
				line-height: 30px;
				font-size: 14px;
				padding-bottom: 60px;
				margin-top: 10px;
			}
			
			.swiper_slide_div:hover .sw_text1{
				color: #fff;
				transition: all 0.5s;
			}
			
			.swiper_slide_div:hover .sw_text2{
				color: #fff;
				transition: all 0.5s;
			}
			
			.swiper_slide_div:hover .sw_text3{
				color: #fff;
				transition: all 0.5s;
			}
			
			.dot {
				width: 110px;
				height: 110px;
				background: rgba(255,255,255,0.6);
				border-radius: 50%;
				// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
				-webkit-animation-name: ripple;
				/*动画属性名，也就是我们前面keyframes定义的动画名*/
				-webkit-animation-duration: 2s;
				/*动画持续时间*/
				-webkit-animation-timing-function: ease;
				/*动画频率，和transition-timing-function是一样的*/
				-webkit-animation-delay: 0s;
				/*动画延迟时间*/
				-webkit-animation-iteration-count: infinite;
				/*定义循环资料，infinite为无限次*/
				-webkit-animation-direction: alternate;
				/*定义动画方式*/
			}
			
			.dot2 {
				width: 90px;
				height: 90px;
				position: absolute;
				margin: 0 auto;
				background: rgba(255,255,255);
				border-radius: 50%;
				// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
				-webkit-animation-name: ripple2;
				/*动画属性名，也就是我们前面keyframes定义的动画名*/
				-webkit-animation-duration: 1.6s;
				/*动画持续时间*/
				-webkit-animation-timing-function: ease;
				/*动画频率，和transition-timing-function是一样的*/
				-webkit-animation-delay: 0s;
				/*动画延迟时间*/
				-webkit-animation-iteration-count: infinite;
				/*定义循环资料，infinite为无限次*/
				-webkit-animation-direction: alternate;
				/*定义动画方式*/
			}
			
			.do_icon{
				width: 40px;
				height: 40px;
				position: absolute;
				margin: 0 auto;
			}
			
			@keyframes ripple {
				0% {
			
					width: 100px;
					height: 100px;
				}
			
				100% {
			
					width: 120px;
					height: 120px;
				}
			}
			
			@keyframes ripple2 {
				0% {
			
					width: 80px;
					height: 80px;
				}
			
				100% {
			
					width: 100px;
					height: 100px;
				}
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			.serviceItems {
				background-size: cover;
				backgorund-position: center center;
				padding: 80px 60px;
				position: relative;
				z-index: 9;
				background-image: url(http://static601.yun300.cn/img/bg_serv01.jpg?tenantId=252768&viewType=1&v=1678089441000);
			
				.si_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
			
				.si_title_cn {
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
			}
			
			.theSwiper {
				width: 100%;
				margin-top: 50px;
			
				.swiper-container {
					position: relative;
					// height: 600px;
			
					.swiper-slide {
						width: calc((100% - 12vw) / 5);
						// line-height: 600px;
						color: rgb(255, 255, 255);
						background: rgba(255,255,255,0.4);
						cursor: pointer;
						font-size: 16px;
						text-align: center;
						z-index: 12;
						position: relative;
						
					}
				}
			}
			
			
			// .swiper-container:hover .swiper-slide{
			// 	background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
			// }
			
			.circle_icon{
				width: 100%;
				height: 120px;
				padding-top: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			
			.swiper_slide_div{
				position: relative;
				width: 100%;
				height: 100%;
			
			}
			
			.mask{
				width: 100%;
				height: 100%;
				content: '';
				// line-height: 600px;
				z-index: 13;
				top: 0;
				left: 0;
				position: absolute;
				background: linear-gradient(to right,rgba(98,30,210,0.85),rgba(48,102,245,0.85));
				opacity: 0;
			}
			
			
			.swiper_slide_div:hover .mask{
				transition: all 0.5s;
				opacity: 1;
			}
			
			.sw_text1{
				text-align: center;
				color: #333;
				font-size: 14px;
				margin-top: 40px;
			}
			
			.sw_text2{
				text-align: center;
				color: #333;
				margin: 40px 40px 0 40px;
				line-height: 30px;
				font-size: 14px;
				height: 150px;
				margin-bottom: 60px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 5;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
			}
			
			.sw_text3{
				text-align: center;
				color: #333;
				line-height: 30px;
				font-size: 14px;
				padding-bottom: 60px;
				margin-top: 10px;
			}
			
			.swiper_slide_div:hover .sw_text1{
				color: #fff;
				transition: all 0.5s;
			}
			
			.swiper_slide_div:hover .sw_text2{
				color: #fff;
				transition: all 0.5s;
			}
			
			.swiper_slide_div:hover .sw_text3{
				color: #fff;
				transition: all 0.5s;
			}
			
			.dot {
				width: 110px;
				height: 110px;
				background: rgba(255,255,255,0.6);
				border-radius: 50%;
				// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
				-webkit-animation-name: ripple;
				/*动画属性名，也就是我们前面keyframes定义的动画名*/
				-webkit-animation-duration: 2s;
				/*动画持续时间*/
				-webkit-animation-timing-function: ease;
				/*动画频率，和transition-timing-function是一样的*/
				-webkit-animation-delay: 0s;
				/*动画延迟时间*/
				-webkit-animation-iteration-count: infinite;
				/*定义循环资料，infinite为无限次*/
				-webkit-animation-direction: alternate;
				/*定义动画方式*/
			}
			
			.dot2 {
				width: 90px;
				height: 90px;
				position: absolute;
				margin: 0 auto;
				background: rgba(255,255,255);
				border-radius: 50%;
				// box-shadow: 0 0 10px rgba(0, 0, 0, .3) inset;
				-webkit-animation-name: ripple2;
				/*动画属性名，也就是我们前面keyframes定义的动画名*/
				-webkit-animation-duration: 1.6s;
				/*动画持续时间*/
				-webkit-animation-timing-function: ease;
				/*动画频率，和transition-timing-function是一样的*/
				-webkit-animation-delay: 0s;
				/*动画延迟时间*/
				-webkit-animation-iteration-count: infinite;
				/*定义循环资料，infinite为无限次*/
				-webkit-animation-direction: alternate;
				/*定义动画方式*/
			}
			
			.do_icon{
				width: 40px;
				height: 40px;
				position: absolute;
				margin: 0 auto;
			}
			
			@keyframes ripple {
				0% {
			
					width: 100px;
					height: 100px;
				}
			
				100% {
			
					width: 120px;
					height: 120px;
				}
			}
			
			@keyframes ripple2 {
				0% {
			
					width: 80px;
					height: 80px;
				}
			
				100% {
			
					width: 100px;
					height: 100px;
				}
			}
		
	}
</style>