<template>
	<div class="informationCenter">
		<div v-if="windowWidth>=1440">
			<div class="ic_div">
				<div style="text-align: center;">
					<!-- <div class="ic_div_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="10">News</div> -->
					<div class="ic_div_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="10">{{$t('homeAbout.title5')}}</div>
				</div>
			
				<!-- <div class="ic_div_switch">
					<div v-for="(item,index) in informationList" :key="index" :class="informationActive==index ? 'text2':'text'" @click="informationClick(index,1,3)">{{item}}</div>
				</div> -->
			
				<div class="ic_div_content">
					<div class="ic_div_ct_leftDiv wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="10">
						<!-- <el-image class="ic_div_ct_div_image" :src="require('@/assets/img/NEW.png')"></el-image> -->
						<div class="ic_div_ct_div_content">
							<div class="ic_div_ct_div_ct_textDiv">
								<div class="ic_div_ct_div_ct_td_text">{{setText(newsListContent[1].articleContent)}}</div>
								<div class="ic_div_ct_div_ct_td_background"></div>
							</div>
						</div>
						<el-image class="ic_div_ct_div_new" :src="newsListContent[1].Image" fit="cover"></el-image>
					</div>
			
					<div class="ic_div_ct_rightDiv wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="10">
						<div class="ic_div_ct_rd_contentList" v-for="(item,index) in newsListContent" :key="index"  @mousemove="newsMoveEvent($event,index)" @mouseleave="newsLeveEvent($event,index)" @click="journalismDetailJump(item)">
							<div class="ic_div_ct_rd_ct_timeDiv">
								<div class="ic_div_ct_rd_ct_td_num" :style="newsSelect==index ? 'color: #1074bc;' : ''">{{index+1<=9 ? '0' : ''}}{{index+1}}</div>
							</div>
			
							<div class="ic_div_ct_rd_ct_informationContent">
								<div class="ic_div_ct_rd_ct_ic_title" :style="newsSelect==index ? 'color: #1074bc;' : ''">
									{{item.articleTitle}}</div>
								<div class="ic_div_ct_rd_ct_ic_articleContent" style="overflow:hidden;text-overflow:ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;" :style="newsSelect==index ? 'color: #1074bc;' : ''">
									{{setText(item.articleContent)}}</div>
							</div>
			
							<div class="ic_div_ct_rd_ct_viewDetails" :style="newsSelect==index ? 'color: #1074bc;' : ''">
								查看详情 +</div>
						</div>
					</div>
				</div>
			
				<div class="ic_div_bottom wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="10">
					<!-- <div class="ic_div_exploreMore" @click="jump">探索更多</div> -->
					<div class="btn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" @click="jump">
						<a class="learnMore"></a>
						<a class="learnMore2">{{$t('homeAbout.newsButton')}}</a>
						<!-- <a class="learnMore2">LEARN MORE</a> -->
					</div>
			
					<div class="ic_div_bt_turnPages">
						<i class="el-icon-back" style="padding: 10px;border: 1px solid #000;border-radius: 20px;" @click="previousPage"></i>
						<i class="el-icon-right"
							style="padding: 10px;border: 1px solid #000;border-radius: 20px;margin-left: 20px;" @click="nextPage"></i>
					</div>
				</div>
			</div>
			<vue-particles
				  class="login-bg"
			      color="#b9b9b9"
			      :particle-opacity="0.7"
			      :particles-number="100"
			      shape-type="circle"
			      :particle-size="4"
			      lines-color="#d0d0d0"
			      :lines-width="1"
			      :line-linked="true"
			      :line-opacity="0.4"
			      :lines-distance="150"
			      :move-speed="2"
			      :hover-effect="true"
			      hover-mode="grab"
			      :click-effect="true"
			      click-mode="push"
			    />
		</div>
		
		<div v-if="windowWidth<=1439">
			<div class="ic_div">
				<div style="text-align: center;">
					<!-- <div class="ic_div_title_en wow fadeInUp wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="10">News</div> -->
					<div class="ic_div_title_cn wow fadeInUp wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="10">{{$t('homeAbout.title5')}}</div>
				</div>
			
				<!-- <div class="ic_div_switch">
					<div v-for="(item,index) in informationList" :key="index" :class="informationActive==index ? 'text2':'text'" @click="informationClick(index,1,3)">{{item}}</div>
				</div> -->
			
				<div class="ic_div_content">
					<div class="ic_div_ct_leftDiv wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="10">
						<!-- <el-image class="ic_div_ct_div_image" :src="require('@/assets/img/NEW.png')"></el-image> -->
						<div class="ic_div_ct_div_content">
							<div class="ic_div_ct_div_ct_textDiv">
								<div class="ic_div_ct_div_ct_td_text">{{setText(newsListContent[1].articleContent)}}</div>
								<div class="ic_div_ct_div_ct_td_background"></div>
							</div>
						</div>
						<el-image class="ic_div_ct_div_new" :src="require('@/assets/img/newImage1.png')" fit="cover"></el-image>
					</div>
			
					<div class="ic_div_ct_rightDiv wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="10">
						<div class="ic_div_ct_rd_contentList" v-for="(item,index) in newsListContent" :key="index"  @mousemove="newsMoveEvent($event,index)" @mouseleave="newsLeveEvent($event,index)" @click="journalismDetailJump(item)">
							<div class="ic_div_ct_rd_ct_timeDiv">
								<div class="ic_div_ct_rd_ct_td_num" :style="newsSelect==index ? 'color: #1074bc;' : ''">{{index+1<=9 ? '0' : ''}}{{index+1}}</div>
							</div>
			
							<div class="ic_div_ct_rd_ct_informationContent">
								<div class="ic_div_ct_rd_ct_ic_title" :style="newsSelect==index ? 'color: #1074bc;' : ''">
									{{item.articleTitle}}</div>
								<div class="ic_div_ct_rd_ct_ic_articleContent" style="overflow:hidden;text-overflow:ellipsis;display: -webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;" :style="newsSelect==index ? 'color: #1074bc;' : ''">
									{{setText(item.articleContent)}}</div>
							</div>
			
							<!-- <div class="ic_div_ct_rd_ct_viewDetails" :style="newsSelect==index ? 'color: #1074bc;' : ''">
								查看详情 +</div> -->
						</div>
					</div>
				</div>
			
				<div class="ic_div_bottom wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="10">
					<!-- <div class="ic_div_exploreMore" @click="jump">探索更多</div> -->
					<div class="btn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" @click="jump">
						<a class="learnMore"></a>
						<a class="learnMore2">{{$t('homeAbout.newsButton')}}</a>
						<!-- <a class="learnMore2">LEARN MORE</a> -->
					</div>
			
					<div class="ic_div_bt_turnPages">
						<i class="el-icon-back" style="padding: 10px;border: 1px solid #000;border-radius: 20px;" @click="previousPage"></i>
						<i class="el-icon-right"
							style="padding: 10px;border: 1px solid #000;border-radius: 20px;margin-left: 20px;" @click="nextPage"></i>
					</div>
				</div>
			</div>
			<vue-particles
				  class="login-bg"
			      color="#b9b9b9"
			      :particle-opacity="0.7"
			      :particles-number="100"
			      shape-type="circle"
			      :particle-size="4"
			      lines-color="#d0d0d0"
			      :lines-width="1"
			      :line-linked="true"
			      :line-opacity="0.4"
			      :lines-distance="150"
			      :move-speed="2"
			      :hover-effect="true"
			      hover-mode="grab"
			      :click-effect="true"
			      click-mode="push"
			    />
		</div>
	</div>
</template>

<script>
	import particles from 'particles.js';
	import {dataInJs} from './data.js';
	import WOW from "wowjs";
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				informationActive:0,//资讯中心下标
				informationList:['所有','行业资讯','公司资讯'],
				newsListContent:dataInJs(),//资讯中心列表
				//判断是否鼠标移入新闻
				newsSelect:null,
				pageNum:0,//分页缓存
				
			}
		},

		created() {
			this.$nextTick(()=>{
				this.$forceUpdate()
			})
			this.newsListContent = dataInJs(this.$i18n.locale)
			// this.informationClick(0,1,3)
			// Vue.component('vue-particles', particles)
		},

		watch: {
			//双向绑定语言切换
			'$i18n.locale'(val) {
				if (this.$i18n.locale == 'cn') {
					this.newsListContent = dataInJs('cn')//资讯中心列表
				} else {
					this.newsListContent = dataInJs('en')//资讯中心列表
				}
			}
		},

		computed: {
			
		},

		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
		},

		beforeDestroy() {
			if (pJSDom && pJSDom.length > 0) {
				pJSDom.forEach(pJSDomItem => {
					pJSDomItem.pJS.fn.vendors.destroypJS()
				})
			}
		},

		methods: {
			//--------------资讯中心类型切换---------------------
			informationClick:function(e,num,size){
				this.informationActive=e
				
				let data={
					articleClass:null,
					pageNum: num,
					pageSize: size,
				}
				this.pageNum=num;
				if(e==1){
					data.articleClass=1
				}else if(e==2){
					data.articleClass=2
				}
				websiteList(data).then(res => {
					this.newsListContent = res.rows
					this.newsListContent.map(res=>{
						res['time']=res.createTime.split('-')
						res.time[2]=res.time[2].split(' ')[0]
					})
					console.log(this.newsListContent)
				})
			},
			
			// ----------------------去除标签-------------------------------------
			setText(html) {
				return html.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n')
				.replace(/<br\/?>/gi, '\n')
				.replace(/<[^>/]+>/g, '')
				.replace(/(\n)?<\/([^>]+)>/g, '')
				.replace(/\u00a0/g, ' ')
				.replace(/&nbsp;/g, ' ')
				.replace(/<img[^>]+src\\s*=\\s*['\"]([^'\"]+)['\"][^>]*>/g, '')
				.replace(/<\/?(img|table|br)[^>]*>/g, "")   // 去除图片和表格
				.replace(/<\/?(a)[^>]*>/g, "")			//  去除a标签
			},
			
			//-----------新闻鼠标移入---------------------------
			newsMoveEvent: function(event, index) {
				this.newsSelect = index
			},
			
			//-----------新闻鼠标移出---------------------------
			newsLeveEvent:function(event,index){
				this.newsSelect = null
			},
			
			//-----------跳转新闻详情----------------
			journalismDetailJump:function(item){
				this.$router.push({
					path:'/details',
					query: item
				})
			},
			
			//---------跳转-------------------
			jump:function(){
				this.$router.push({
					path: '/news',
				})
			},
			
			//--------------上一页-----------------------
			previousPage:function(){
				if(this.pageNum>1){
					this.pageNum--
					this.informationClick(this.informationActive,this.pageNum,3);
				}
			},
			
			//-------------下一页-------------------------
			nextPage:function(){
				if(this.newsListContent.length==3){
					this.pageNum++
					this.informationClick(this.informationActive,this.pageNum,3);
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	@font-face {
		font-family: 'BigJohn';
		src: url('../../../assets/big-john-woff2-2.ttf') format('truetype');
	}
	
	@font-face {
		font-family: 'zhenyan';
		src: url('../../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}
	
	.btn {
		// margin-top: 40px;
		width: 190px;
		line-height: 52px;
		height: 50px;
		border: 1px solid #333;
		color: #333;
		font-family: BigJohn;
		font-weight: bold;
		text-align: center;
		border-radius: 5px;
		user-select: none;
		cursor: pointer;
		position: relative;
		font-size: 16px;
		overflow: hidden;
	}
	
	.btn .learnMore {
		width: 100%;
		height: 100%;
		background: linear-gradient(to right, #621ed2, #3066f5);
		// background-repeat: no-repeat;
		left: -100%;
		position: absolute;
		transition: 0.6s;
	}
	
	.btn:hover .learnMore {
		left: 0%;
		color: #fff;
		// z-index: 4;
	}
	
	.btn .learnMore2 {
		position: relative;
		transition: 0.6s;
	}
	
	.btn:hover {
		border: 1px solid #fff;
		transition: 0.6s;
	}
	
	.btn:hover .learnMore2 {
		color: #fff;
		// z-index: 3;
	}
	
	.btn:active {
		box-shadow: 0 0 3px #ccc;
	}
	
	@media only screen and (min-width:1920px) {
		.login-bg{
			position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;
		}
		
		.informationCenter{
			padding: 60px 200px;
			position: relative;
			
			.ic_div{
				z-index: 3;
				position: relative;
				
				.ic_div_title_en{
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ic_div_title_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ic_div_switch{
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					padding-top: 40px;
					
					.text{
						padding: 7px 40px;
						border: 1px solid #000;
						border-radius: 20px;
						font-size: 20px;
						margin-left: 20px;
					}
					
					.text2{
						padding: 7px 40px;
						border-radius: 20px;
						font-size: 20px;
						color: #fff;
						background-image: -webkit-linear-gradient(left, #fa7172,#d1112f);
						margin-left: 20px;
					}
				}
				
				.ic_div_content{
					display: flex;
					width: 100%;
					padding-top: 40px;
					
					.ic_div_ct_leftDiv{
						width: 40%;
						position: relative;
						
						.ic_div_ct_div_image{
							width: 50px;
							height: 50px;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 4;
						}
						
						.ic_div_ct_div_content{
							position: absolute;
							width: 100%;
							z-index: 4;
							bottom: 0;
							left: 0;
							
							.ic_div_ct_div_ct_textDiv{
								width: 100%;
								position: relative;
								padding: 10px 20px;
								
								.ic_div_ct_div_ct_td_text{
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
									font-size: 24px;
									font-weight: 100;
									z-index: 5;
									position: relative;
									color: #fff;
								}
								
								.ic_div_ct_div_ct_td_background{
									position: absolute;
									top: 0;
									left: 0;
									background: linear-gradient(to right,rgba(98,30,210),rgba(48,102,245));
									width: 100%;
									height: 100%;
									z-index: 4;
									// opacity: .8;
								}
							}
						}
						
						.ic_div_ct_div_new{
							width: 100%;
							height: 420px;
							z-index: 3;
						}
					}
					
					.ic_div_ct_rightDiv{
						width: 60%;
						position: relative;
						padding-left: 30px;
						
						.ic_div_ct_rd_contentList{
							display: flex;
							padding: 20px 0;
							border-bottom: 1px solid #b9b9b9;
							color: #b9b9b9;
							
							.ic_div_ct_rd_ct_timeDiv{
								width: 15%;
								text-align: center;
								display: flex;
								align-items: center;
								justify-content: center;
								
								.ic_div_ct_rd_ct_td_num{
									font-family: BigJohn;
									font-size: 70px;
								}
								
							}
							
							.ic_div_ct_rd_ct_informationContent{
								width: 75%;
								padding-left: 20px;
								cursor: pointer;
								
								.ic_div_ct_rd_ct_ic_title{
									color: #333;
									font-size: 18px;
									font-weight: bold;
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
								}
								
								.ic_div_ct_rd_ct_ic_articleContent{
									font-size: 14px;
									line-height: 24px;
									color: #666;
									padding-top: 10px;
								}
							}
							
							.ic_div_ct_rd_ct_viewDetails{
								font-size: 16px;
								display: flex;
								align-items: center;
								font-weight: 100;
								width: 14%;
								justify-content: right;
								cursor: pointer;
							}
						}
					}
				}
				
				.ic_div_bottom{
					display: flex;
					padding-top: 40px;
					position: relative;
					display: flex;
					cursor: pointer;
					
					.ic_div_exploreMore{
						padding: 10px 50px;
						border-radius: 5px;
						border: 1px solid #000;
					}
					
					.ic_div_bt_turnPages{
						position: absolute;
						right: 0;
						top: 0;
						display: flex;
						align-items: center;
						padding-top: 40px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.login-bg{
			position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;
		}
		
		.informationCenter{
			padding: 60px 200px;
			position: relative;
			
			.ic_div{
				z-index: 3;
				position: relative;
				
				.ic_div_title_en{
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ic_div_title_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ic_div_switch{
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					padding-top: 40px;
					
					.text{
						padding: 7px 40px;
						border: 1px solid #000;
						border-radius: 20px;
						font-size: 20px;
						margin-left: 20px;
					}
					
					.text2{
						padding: 7px 40px;
						border-radius: 20px;
						font-size: 20px;
						color: #fff;
						background-image: -webkit-linear-gradient(left, #fa7172,#d1112f);
						margin-left: 20px;
					}
				}
				
				.ic_div_content{
					display: flex;
					width: 100%;
					padding-top: 40px;
					
					.ic_div_ct_leftDiv{
						width: 40%;
						position: relative;
						
						.ic_div_ct_div_image{
							width: 50px;
							height: 50px;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 4;
						}
						
						.ic_div_ct_div_content{
							position: absolute;
							width: 100%;
							z-index: 4;
							bottom: 0;
							left: 0;
							
							.ic_div_ct_div_ct_textDiv{
								width: 100%;
								position: relative;
								padding: 10px 20px;
								
								.ic_div_ct_div_ct_td_text{
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
									font-size: 24px;
									font-weight: 100;
									z-index: 5;
									position: relative;
									color: #fff;
								}
								
								.ic_div_ct_div_ct_td_background{
									position: absolute;
									top: 0;
									left: 0;
									background: linear-gradient(to right,rgba(98,30,210,),rgba(48,102,245));
									width: 100%;
									height: 100%;
									z-index: 4;
									// opacity: .8;
								}
							}
						}
						
						.ic_div_ct_div_new{
							width: 100%;
							height: 420px;
							z-index: 3;
						}
					}
					
					.ic_div_ct_rightDiv{
						width: 60%;
						position: relative;
						padding-left: 30px;
						
						.ic_div_ct_rd_contentList{
							display: flex;
							padding: 20px 0;
							border-bottom: 1px solid #b9b9b9;
							color: #b9b9b9;
							
							.ic_div_ct_rd_ct_timeDiv{
								width: 15%;
								text-align: center;
								
								.ic_div_ct_rd_ct_td_num{
									font-family: BigJohn;
									font-size: 70px;
								}
							}
							
							.ic_div_ct_rd_ct_informationContent{
								width: 75%;
								padding-left: 20px;
								cursor: pointer;
								
								.ic_div_ct_rd_ct_ic_title{
									color: #333;
									font-size: 18px;
									font-weight: bold;
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
								}
								
								.ic_div_ct_rd_ct_ic_articleContent{
									font-size: 14px;
									line-height: 24px;
									color: #666;
									padding-top: 10px;
								}
							}
							
							.ic_div_ct_rd_ct_viewDetails{
								font-size: 16px;
								display: flex;
								align-items: center;
								font-weight: 100;
								width: 14%;
								justify-content: right;
								cursor: pointer;
							}
						}
					}
				}
				
				.ic_div_bottom{
					display: flex;
					padding-top: 40px;
					position: relative;
					display: flex;
					cursor: pointer;
					
					.ic_div_exploreMore{
						padding: 10px 50px;
						border-radius: 5px;
						border: 1px solid #000;
					}
					
					.ic_div_bt_turnPages{
						position: absolute;
						right: 0;
						top: 0;
						display: flex;
						align-items: center;
						padding-top: 40px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.login-bg{
			position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;
		}
		
		.informationCenter{
			padding: 60px 200px;
			position: relative;
			
			.ic_div{
				z-index: 3;
				position: relative;
				
				.ic_div_title_en{
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ic_div_title_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ic_div_switch{
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					padding-top: 40px;
					
					.text{
						padding: 7px 40px;
						border: 1px solid #000;
						border-radius: 20px;
						font-size: 20px;
						margin-left: 20px;
					}
					
					.text2{
						padding: 7px 40px;
						border-radius: 20px;
						font-size: 20px;
						color: #fff;
						background-image: -webkit-linear-gradient(left, #fa7172,#d1112f);
						margin-left: 20px;
					}
				}
				
				.ic_div_content{
					display: flex;
					width: 100%;
					padding-top: 40px;
					
					.ic_div_ct_leftDiv{
						width: 40%;
						position: relative;
						
						.ic_div_ct_div_image{
							width: 50px;
							height: 50px;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 4;
						}
						
						.ic_div_ct_div_content{
							position: absolute;
							width: 100%;
							z-index: 4;
							bottom: 0;
							left: 0;
							
							.ic_div_ct_div_ct_textDiv{
								width: 100%;
								position: relative;
								padding: 10px 20px;
								
								.ic_div_ct_div_ct_td_text{
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
									font-size: 24px;
									font-weight: 100;
									z-index: 5;
									position: relative;
									color: #fff;
								}
								
								.ic_div_ct_div_ct_td_background{
									position: absolute;
									top: 0;
									left: 0;
									background: linear-gradient(to right,rgba(98,30,210),rgba(48,102,245));
									width: 100%;
									height: 100%;
									z-index: 4;
									// opacity: .8;
								}
							}
						}
						
						.ic_div_ct_div_new{
							width: 100%;
							height: 420px;
							z-index: 3;
						}
					}
					
					.ic_div_ct_rightDiv{
						width: 60%;
						position: relative;
						padding-left: 30px;
						
						.ic_div_ct_rd_contentList{
							display: flex;
							padding: 20px 0;
							border-bottom: 1px solid #b9b9b9;
							color: #b9b9b9;
							
							.ic_div_ct_rd_ct_timeDiv{
								width: 15%;
								text-align: center;
								
								.ic_div_ct_rd_ct_td_num{
									font-family: BigJohn;
									font-size: 70px;
								}
							}
							
							.ic_div_ct_rd_ct_informationContent{
								width: 75%;
								padding-left: 20px;
								cursor: pointer;
								
								.ic_div_ct_rd_ct_ic_title{
									color: #333;
									font-size: 18px;
									font-weight: bold;
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
								}
								
								.ic_div_ct_rd_ct_ic_articleContent{
									font-size: 14px;
									line-height: 24px;
									color: #666;
									padding-top: 10px;
								}
							}
							
							.ic_div_ct_rd_ct_viewDetails{
								font-size: 24px;
								display: flex;
								align-items: center;
								font-weight: 100;
								width: 14%;
								justify-content: right;
								cursor: pointer;
							}
						}
					}
				}
				
				.ic_div_bottom{
					display: flex;
					padding-top: 40px;
					position: relative;
					display: flex;
					cursor: pointer;
					
					.ic_div_exploreMore{
						padding: 10px 50px;
						border-radius: 5px;
						border: 1px solid #000;
					}
					
					.ic_div_bt_turnPages{
						position: absolute;
						right: 0;
						top: 0;
						display: flex;
						align-items: center;
						padding-top: 40px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.login-bg{
			position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;
		}
		
		.informationCenter{
			padding: 60px 200px;
			position: relative;
			
			.ic_div{
				z-index: 3;
				position: relative;
				
				.ic_div_title_en{
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ic_div_title_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ic_div_switch{
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					padding-top: 40px;
					
					.text{
						padding: 7px 40px;
						border: 1px solid #000;
						border-radius: 20px;
						font-size: 20px;
						margin-left: 20px;
					}
					
					.text2{
						padding: 7px 40px;
						border-radius: 20px;
						font-size: 20px;
						color: #fff;
						background-image: -webkit-linear-gradient(left, #fa7172,#d1112f);
						margin-left: 20px;
					}
				}
				
				.ic_div_content{
					display: block;
					width: 100%;
					padding-top: 40px;
					
					.ic_div_ct_leftDiv{
						width: 100%;
						position: relative;
						
						.ic_div_ct_div_image{
							width: 50px;
							height: 50px;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 4;
						}
						
						.ic_div_ct_div_content{
							position: absolute;
							width: 100%;
							z-index: 4;
							bottom: 0;
							left: 0;
							
							.ic_div_ct_div_ct_textDiv{
								width: 100%;
								position: relative;
								padding: 10px 20px;
								
								.ic_div_ct_div_ct_td_text{
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
									font-size: 24px;
									font-weight: 100;
									z-index: 5;
									position: relative;
									color: #fff;
								}
								
								.ic_div_ct_div_ct_td_background{
									position: absolute;
									top: 0;
									left: 0;
									background: linear-gradient(to right,rgba(98,30,210),rgba(48,102,245));
									width: 100%;
									height: 100%;
									z-index: 4;
									// opacity: .8;
								}
							}
						}
						
						.ic_div_ct_div_new{
							width: 100%;
							height: 420px;
							z-index: 3;
						}
					}
					
					.ic_div_ct_rightDiv{
						width: 100%;
						position: relative;
						padding-left: 30px;
						
						.ic_div_ct_rd_contentList{
							display: flex;
							padding: 20px 0;
							border-bottom: 1px solid #b9b9b9;
							color: #b9b9b9;
							
							.ic_div_ct_rd_ct_timeDiv{
								width: 15%;
								text-align: center;
								
								.ic_div_ct_rd_ct_td_num{
									font-family: BigJohn;
									font-size: 70px;
								}
							}
							
							.ic_div_ct_rd_ct_informationContent{
								width: 75%;
								padding-left: 20px;
								cursor: pointer;
								
								.ic_div_ct_rd_ct_ic_title{
									color: #333;
									font-size: 18px;
									font-weight: bold;
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
								}
								
								.ic_div_ct_rd_ct_ic_articleContent{
									font-size: 14px;
									line-height: 24px;
									color: #666;
									padding-top: 10px;
								}
							}
							
							.ic_div_ct_rd_ct_viewDetails{
								font-size: 24px;
								display: flex;
								align-items: center;
								font-weight: 100;
								width: 14%;
								justify-content: right;
								cursor: pointer;
							}
						}
					}
				}
				
				.ic_div_bottom{
					display: flex;
					padding-top: 40px;
					position: relative;
					display: flex;
					cursor: pointer;
					
					.ic_div_exploreMore{
						padding: 10px 50px;
						border-radius: 5px;
						border: 1px solid #000;
					}
					
					.ic_div_bt_turnPages{
						position: absolute;
						right: 0;
						top: 0;
						display: flex;
						align-items: center;
						padding-top: 40px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.login-bg{
			position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;
		}
		
		.informationCenter{
			padding: 40px;
			position: relative;
			
			.ic_div{
				z-index: 3;
				position: relative;
				
				.ic_div_title_en{
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ic_div_title_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ic_div_switch{
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					padding-top: 40px;
					
					.text{
						padding: 7px 40px;
						border: 1px solid #000;
						border-radius: 20px;
						font-size: 20px;
						margin-left: 20px;
					}
					
					.text2{
						padding: 7px 40px;
						border-radius: 20px;
						font-size: 20px;
						color: #fff;
						background-image: -webkit-linear-gradient(left, #fa7172,#d1112f);
						margin-left: 20px;
					}
				}
				
				.ic_div_content{
					display: block;
					width: 100%;
					padding-top: 40px;
					
					.ic_div_ct_leftDiv{
						width: 100%;
						position: relative;
						
						.ic_div_ct_div_image{
							width: 50px;
							height: 50px;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 4;
						}
						
						.ic_div_ct_div_content{
							position: absolute;
							width: 100%;
							z-index: 4;
							bottom: 0;
							left: 0;
							
							.ic_div_ct_div_ct_textDiv{
								width: 100%;
								position: relative;
								padding: 10px 20px;
								
								.ic_div_ct_div_ct_td_text{
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
									font-size: 24px;
									font-weight: 100;
									z-index: 5;
									position: relative;
									color: #fff;
								}
								
								.ic_div_ct_div_ct_td_background{
									position: absolute;
									top: 0;
									left: 0;
									background: linear-gradient(to right,rgba(98,30,210),rgba(48,102,245));
									width: 100%;
									height: 100%;
									z-index: 4;
									// opacity: .8;
								}
							}
						}
						
						.ic_div_ct_div_new{
							width: 100%;
							height: 420px;
							z-index: 3;
						}
					}
					
					.ic_div_ct_rightDiv{
						width: 100%;
						position: relative;
						padding-left: 30px;
						
						.ic_div_ct_rd_contentList{
							display: flex;
							padding: 20px 0;
							border-bottom: 1px solid #b9b9b9;
							color: #b9b9b9;
							
							.ic_div_ct_rd_ct_timeDiv{
								width: 15%;
								text-align: center;
								
								.ic_div_ct_rd_ct_td_num{
									font-family: BigJohn;
									font-size: 70px;
								}
							}
							
							.ic_div_ct_rd_ct_informationContent{
								width: 75%;
								padding-left: 20px;
								cursor: pointer;
								
								.ic_div_ct_rd_ct_ic_title{
									color: #333;
									font-size: 18px;
									font-weight: bold;
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
								}
								
								.ic_div_ct_rd_ct_ic_articleContent{
									font-size: 14px;
									line-height: 24px;
									color: #666;
									padding-top: 10px;
								}
							}
							
							.ic_div_ct_rd_ct_viewDetails{
								font-size: 24px;
								display: flex;
								align-items: center;
								font-weight: 100;
								width: 14%;
								justify-content: right;
								cursor: pointer;
							}
						}
					}
				}
				
				.ic_div_bottom{
					display: flex;
					padding-top: 40px;
					position: relative;
					display: flex;
					cursor: pointer;
					
					.ic_div_exploreMore{
						padding: 10px 50px;
						border-radius: 5px;
						border: 1px solid #000;
					}
					
					.ic_div_bt_turnPages{
						position: absolute;
						right: 0;
						top: 0;
						display: flex;
						align-items: center;
						padding-top: 40px;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.login-bg{
			position: absolute;top: 0;left: 0;z-index: 1;width: 100%;height: 100%;
		}
		
		.informationCenter{
			padding: 40px;
			position: relative;
			
			.ic_div{
				z-index: 3;
				position: relative;
				
				.ic_div_title_en{
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ic_div_title_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ic_div_switch{
					display: flex;
					width: 100%;
					justify-content: center;
					align-items: center;
					padding-top: 40px;
					
					.text{
						padding: 7px 40px;
						border: 1px solid #000;
						border-radius: 20px;
						font-size: 20px;
						margin-left: 20px;
					}
					
					.text2{
						padding: 7px 40px;
						border-radius: 20px;
						font-size: 20px;
						color: #fff;
						background-image: -webkit-linear-gradient(left, #fa7172,#d1112f);
						margin-left: 20px;
					}
				}
				
				.ic_div_content{
					display: block;
					width: 100%;
					padding-top: 40px;
					
					.ic_div_ct_leftDiv{
						width: 100%;
						position: relative;
						
						.ic_div_ct_div_image{
							width: 50px;
							height: 50px;
							position: absolute;
							top: 0;
							left: 0;
							z-index: 4;
						}
						
						.ic_div_ct_div_content{
							position: absolute;
							width: 100%;
							z-index: 4;
							bottom: 0;
							left: 0;
							
							.ic_div_ct_div_ct_textDiv{
								width: 100%;
								position: relative;
								padding: 10px 20px;
								
								.ic_div_ct_div_ct_td_text{
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
									font-size: 24px;
									font-weight: 100;
									z-index: 5;
									position: relative;
									color: #fff;
								}
								
								.ic_div_ct_div_ct_td_background{
									position: absolute;
									top: 0;
									left: 0;
									background: linear-gradient(to right,rgba(98,30,210),rgba(48,102,245));
									width: 100%;
									height: 100%;
									z-index: 4;
									// opacity: .8;
								}
							}
						}
						
						.ic_div_ct_div_new{
							width: 100%;
							height: 420px;
							z-index: 3;
						}
					}
					
					.ic_div_ct_rightDiv{
						width: 100%;
						position: relative;
						padding-left: 30px;
						
						.ic_div_ct_rd_contentList{
							display: flex;
							height: 100%;
							align-items: center;
							padding: 20px 0;
							border-bottom: 1px solid #b9b9b9;
							color: #b9b9b9;
							
							.ic_div_ct_rd_ct_timeDiv{
								width: 15%;
								text-align: center;
								
								.ic_div_ct_rd_ct_td_num{
									font-family: BigJohn;
									font-size: 30px;
								}
							}
							
							.ic_div_ct_rd_ct_informationContent{
								width: 75%;
								padding-left: 20px;
								cursor: pointer;
								
								.ic_div_ct_rd_ct_ic_title{
									color: #333;
									font-size: 14px;
									font-weight: bold;
									overflow: hidden;
									text-overflow:ellipsis;
									white-space: nowrap;
								}
								
								.ic_div_ct_rd_ct_ic_articleContent{
									font-size: 14px;
									line-height: 24px;
									color: #666;
									padding-top: 10px;
								}
							}
							
							.ic_div_ct_rd_ct_viewDetails{
								font-size: 24px;
								display: flex;
								align-items: center;
								font-weight: 100;
								width: 14%;
								justify-content: right;
								cursor: pointer;
							}
						}
					}
				}
				
				.ic_div_bottom{
					display: flex;
					padding-top: 40px;
					position: relative;
					display: flex;
					cursor: pointer;
					
					.ic_div_exploreMore{
						padding: 10px 50px;
						border-radius: 5px;
						border: 1px solid #000;
					}
					
					.ic_div_bt_turnPages{
						position: absolute;
						right: 0;
						top: 0;
						display: flex;
						align-items: center;
						padding-top: 40px;
					}
				}
			}
		}
	}
</style>
