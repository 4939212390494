<template>
	<div>
		<div class="qualityAssurance" v-if="windowWidth>=1200">
			<div style="text-align: center;">
				<!-- <div class="qa_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">QUALITY</div> -->
				<div class="qa_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('homeAbout.title3')}}</div>
			</div>
		
			<div class="qa_content">
				<div class="qa_ct_div">
					<div class="qa_ct_div_imageDiv wow fadeInUp" :data-wow-duration="item.time" :data-wow-delay="item.time" data-wow-offset="0" v-for="(item,index) in $t('homeAbout.qualityAssurance')" :key="index" @click="jump">
						<div class="qa_mask2"></div>
						<div class="qa_mask"></div>
						<el-image class="qa_image" :src="item.image" :fit="'cover'"></el-image>
						<div class="qa_text">
							<div style="position: relative;width: 100%;height: 100%;">
								<div class="qa_text_div" style="position: relative;width: 100%;height: 100%;">
									<div class="ql_text_div_title">{{item.title}}</div>
									<div class="ql_text_div_content">{{item.content}}</div>
									<div style="display: inline-block;font-size: 14px;color: #fff;border-bottom: 1px solid #fff;margin-top: 10px;">{{$t('homeAbout.qualityAssuranceButton')}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="qualityAssurance" v-if="windowWidth<=1199">
			<div style="text-align: center;">
				<!-- <div class="qa_title_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">QUALITY</div> -->
				<div class="qa_title_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">{{$t('homeAbout.title3')}}</div>
			</div>
		
			<div class="qa_content">
				<div class="qa_ct_div">
					<div class="qa_ct_div_imageDiv wow fadeInUp" :data-wow-duration="item.time" :data-wow-delay="item.time" data-wow-offset="0" v-for="(item,index) in $t('homeAbout.qualityAssurance')" :key="index" @click="jump">
						<div class="qa_mask2"></div>
						<div class="qa_mask"></div>
						<el-image class="qa_image" :src="item.image" :fit="'cover'"></el-image>
						<div class="qa_text">
							<div style="position: relative;width: 100%;height: 100%;">
								<div class="qa_text_div" style="position: relative;width: 100%;height: 100%;">
									<div class="ql_text_div_title">{{item.title}}</div>
									<div class="ql_text_div_content">{{item.content}}</div>
									<div style="display: inline-block;font-size: 14px;color: #fff;border-bottom: 1px solid #fff;margin-top: 10px;">{{$t('homeAbout.qualityAssuranceButton')}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from "wowjs";
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return {
				
			}
		},
		
		created() {
			
		},
		
		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			//------------------路由跳转-------------------------
			jump:function(){
				this.$router.push({
					path: '/serviceItems',
					query:{
						anchorPointIndex:1
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	@font-face {
		font-family: 'BigJohn';
		src: url('../../../assets/big-john-woff2-2.ttf') format('truetype');
	}
	
	@font-face {
		font-family: 'zhenyan';
		src: url('../../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}
	
	@media only screen and (min-width:1920px) {
		.qualityAssurance {
			position: relative;
			z-index: 9;
			width: 100%;
			padding: 90px 200px 60px 200px;
		
			.qa_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.qa_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		
			.qa_content {
				overflow: visible;
				margin-top: 40px;
		
				.qa_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.qa_ct_div_imageDiv {
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.qa_ct_div_imageDiv .qa_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.qa_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.qa_mask2 {
			z-index: 4;
			background: linear-gradient(to top, rgba(98, 30, 210, 0.85), rgba(48, 102, 245, 0.6));
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask2 {
			bottom: 0;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.qa_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.qa_text_div {
			position: absolute;
			bottom: -85px;
			transition: all 1s;
		}
		
		.ql_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.ql_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 14px;
			line-height: 25px;
			color: #fff;
			height: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.qa_ct_div_imageDiv:hover .qa_text_div {
			bottom: 10px;
		}
		
		.qa_ct_div_imageDiv:hover .ql_text_div_content {
			padding-top: 10px;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.qualityAssurance {
			position: relative;
			z-index: 9;
			width: 100%;
			padding: 90px 200px 60px 200px;
		
			.qa_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.qa_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		
			.qa_content {
				overflow: visible;
				margin-top: 40px;
		
				.qa_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.qa_ct_div_imageDiv {
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.qa_ct_div_imageDiv .qa_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.qa_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.qa_mask2 {
			z-index: 4;
			background: linear-gradient(to top, rgba(98, 30, 210, 0.85), rgba(48, 102, 245, 0.6));
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask2 {
			bottom: 0;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.qa_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.qa_text_div {
			position: absolute;
			bottom: -85px;
			transition: all 1s;
		}
		
		.ql_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.ql_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 14px;
			line-height: 25px;
			color: #fff;
			height: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.qa_ct_div_imageDiv:hover .qa_text_div {
			bottom: 10px;
		}
		
		.qa_ct_div_imageDiv:hover .ql_text_div_content {
			padding-top: 10px;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.qualityAssurance {
			position: relative;
			z-index: 9;
			width: 100%;
			padding: 90px 100px 60px 100px;
		
			.qa_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.qa_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		
			.qa_content {
				overflow: visible;
				margin-top: 40px;
		
				.qa_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.qa_ct_div_imageDiv {
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.qa_ct_div_imageDiv .qa_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.qa_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.qa_mask2 {
			z-index: 4;
			background: linear-gradient(to top, rgba(98, 30, 210, 0.85), rgba(48, 102, 245, 0.6));
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask2 {
			bottom: 0;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.qa_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.qa_text_div {
			position: absolute;
			bottom: -85px;
			transition: all 1s;
		}
		
		.ql_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.ql_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 14px;
			line-height: 25px;
			color: #fff;
			height: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.qa_ct_div_imageDiv:hover .qa_text_div {
			bottom: 10px;
		}
		
		.qa_ct_div_imageDiv:hover .ql_text_div_content {
			padding-top: 10px;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.qualityAssurance {
			position: relative;
			z-index: 9;
			width: 100%;
			padding: 90px 60px 60px 60px;
		
			.qa_title_en {
				display: inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family: BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
		
			.qa_title_cn {
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
		
			.qa_content {
				overflow: visible;
				margin-top: 40px;
		
				.qa_ct_div {
					display: flex;
					justify-content: space-between;
					align-items: center;
				}
			}
		}
		
		.qa_ct_div_imageDiv {
			width: calc(94% / 6);
			height: 400px;
			position: relative;
			overflow: hidden;
			cursor: pointer;
		}
		
		.qa_ct_div_imageDiv .qa_image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		
		.qa_mask {
			z-index: 4;
			background-color: rgba(0, 0, 0, 0.3);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			position: absolute;
			transition: background-color ease .2s;
		}
		
		.qa_mask2 {
			z-index: 4;
			background: linear-gradient(to top, rgba(98, 30, 210, 0.85), rgba(48, 102, 245, 0.6));
			width: 100%;
			height: 100%;
			bottom: -100%;
			left: 0;
			transition: all 0.5s;
			position: absolute;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask2 {
			bottom: 0;
		}
		
		.qa_ct_div_imageDiv:hover .qa_mask {
			background-color: rgba(0, 0, 0, 0);
		}
		
		.qa_text {
			padding: 20px;
			z-index: 999;
			color: #fff;
			position: absolute;
			bottom: 0;
			width: 100%;
			overflow: hidden;
			transition: all ease .5s;
		}
		
		.qa_text_div {
			position: absolute;
			bottom: -85px;
			transition: all 1s;
		}
		
		.ql_text_div_title {
			width: 100%;
			font-weight: bold;
			font-size: 18px;
		}
		
		.ql_text_div_content {
			width: 100%;
			padding-top: 30px;
			font-size: 14px;
			line-height: 25px;
			color: #fff;
			height: 60px;
			display: -webkit-box;
			overflow: hidden;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			text-overflow: ellipsis;
			transition: all .5s;
		}
		
		.qa_ct_div_imageDiv:hover .qa_text_div {
			bottom: 10px;
		}
		
		.qa_ct_div_imageDiv:hover .ql_text_div_content {
			padding-top: 10px;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		
			.qualityAssurance {
				position: relative;
				z-index: 9;
				width: 100%;
				padding: 90px 60px 60px 60px;
			
				.qa_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
			
				.qa_title_cn {
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
			
				.qa_content {
					overflow: visible;
					margin-top: 40px;
			
					.qa_ct_div {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;
					}
				}
			}
			
			.qa_ct_div_imageDiv {
				width: calc(94% / 2);
				margin-top: 20px;
				height: 400px;
				display: flex;
				position: relative;
				// overflow: hidden;
				cursor: pointer;
			}
			
			.qa_ct_div_imageDiv .qa_image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.qa_mask {
				z-index: 4;
				background-color: rgba(0, 0, 0, 0.3);
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				position: absolute;
				transition: background-color ease .2s;
			}
			
			.qa_mask2 {
				z-index: 4;
				background: linear-gradient(to top, rgba(98, 30, 210, 0.85), rgba(48, 102, 245, 0.6));
				width: 100%;
				height: 100%;
				bottom: -100%;
				left: 0;
				transition: all 0.5s;
				position: absolute;
			}
			
			.qa_ct_div_imageDiv:hover .qa_mask2 {
				bottom: 0;
			}
			
			.qa_ct_div_imageDiv:hover .qa_mask {
				background-color: rgba(0, 0, 0, 0);
			}
			
			.qa_text {
				padding: 20px;
				z-index: 999;
				color: #fff;
				position: absolute;
				bottom: 0;
				width: 100%;
				overflow: hidden;
				transition: all ease .5s;
			}
			
			.qa_text_div {
				position: absolute;
				bottom: 10px;
				transition: all 1s;
			}
			
			.ql_text_div_title {
				width: 100%;
				font-weight: bold;
				font-size: 18px;
			}
			
			.ql_text_div_content {
				width: 100%;
				padding-top: 30px;
				font-size: 14px;
				line-height: 25px;
				color: #fff;
				height: 60px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				transition: all .5s;
			}
			
			.qa_ct_div_imageDiv:hover .qa_text_div {
				bottom: 10px;
			}
			
			.qa_ct_div_imageDiv:hover .ql_text_div_content {
				padding-top: 10px;
			}
		
	}
	
	@media only screen and (max-width:767px) {
		
			.qualityAssurance {
				position: relative;
				z-index: 9;
				width: 100%;
				padding: 90px 60px 60px 60px;
			
				.qa_title_en {
					display: inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family: BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
			
				.qa_title_cn {
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
			
				.qa_content {
					overflow: visible;
					margin-top: 40px;
			
					.qa_ct_div {
						display: flex;
						justify-content: space-between;
						align-items: center;
						flex-wrap: wrap;
					}
				}
			}
			
			.qa_ct_div_imageDiv {
				width: calc(94% / 1);
				margin-top: 20px;
				height: 400px;
				position: relative;
				overflow: hidden;
				cursor: pointer;
			}
			
			.qa_ct_div_imageDiv .qa_image {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			
			.qa_mask {
				z-index: 4;
				background-color: rgba(0, 0, 0, 0.3);
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				position: absolute;
				transition: background-color ease .2s;
			}
			
			.qa_mask2 {
				z-index: 4;
				background: linear-gradient(to top, rgba(98, 30, 210, 0.85), rgba(48, 102, 245, 0.6));
				width: 100%;
				height: 100%;
				bottom: -100%;
				left: 0;
				transition: all 0.5s;
				position: absolute;
			}
			
			.qa_ct_div_imageDiv:hover .qa_mask2 {
				bottom: 0;
			}
			
			.qa_ct_div_imageDiv:hover .qa_mask {
				background-color: rgba(0, 0, 0, 0);
			}
			
			.qa_text {
				padding: 20px;
				z-index: 999;
				color: #fff;
				position: absolute;
				bottom: 0;
				width: 100%;
				overflow: hidden;
				transition: all ease .5s;
			}
			
			.qa_text_div {
				position: absolute;
				bottom: 10px;
				transition: all 1s;
			}
			
			.ql_text_div_title {
				width: 100%;
				font-weight: bold;
				font-size: 18px;
			}
			
			.ql_text_div_content {
				width: 100%;
				padding-top: 30px;
				font-size: 14px;
				line-height: 25px;
				color: #fff;
				height: 60px;
				display: -webkit-box;
				overflow: hidden;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				text-overflow: ellipsis;
				transition: all .5s;
			}
			
			.qa_ct_div_imageDiv:hover .qa_text_div {
				bottom: 10px;
			}
			
			.qa_ct_div_imageDiv:hover .ql_text_div_content {
				padding-top: 10px;
			}
		
	}
</style>