<template>
  <div class="home" :style="windowWidth>=1200 ? 'margin-top: 55px;':'margin-top: 55px;'">
    <div class="box" @mouseout="out" @mouseover="over">
      <img
        v-for="(item, index) in list"
        v-show="listIndex === index"
        :key="index"
        :src="item"
        alt=""
      />
      <p class="left" @click="changePage(prevIndex)">&lt;</p>
      <ul>
        <li
          :class="{ color: index == listIndex }"
          v-for="(item, index) in list"
          @click="changePage(index)"
          :key="index"
        ></li>
      </ul>
      <p class="right" @click="changePage(nextIndex)">&gt;</p>
    </div>
  </div>
</template>
 
<script>
export default {
  components: {},
  props: {
	  //宽
	  windowWidth: {
	  	type: Number,
	  	default: 0
	  },
	  //高
	  windowHeight: {
	  	type: Number,
	  	default: 0
	  },
  },
  data() {
    return {
      list: [
        require("@/assets/img/banner2.jpg"),
        require("@/assets/img/banner1.jpg"),
        require("@/assets/img/banner3.jpg"),
      ],
      listIndex: 0, //默认显示第几张图片
      timer: null, //定时器
    };
  },
  computed: {
    //上一张
    prevIndex() {
      if (this.listIndex == 0) {
        return this.list.length - 1;
      } else {
        return this.listIndex - 1;
      }
    },
    //下一张
    nextIndex() {
      if (this.listIndex == this.list.length - 1) {
        return 0;
      } else {
        return this.listIndex + 1;
      }
    },
  },
  methods: {
    
    changePage(index) {
      this.listIndex = index;
    },
    //移除
    out() {
      this.setTimer();
    },
    //移入
    over() {
      clearInterval(this.timer);
    },
    //1秒切图
    setTimer() {
      this.timer = setInterval(() => {
        this.listIndex++;
        if (this.listIndex == this.list.length) {
          this.listIndex = 0;
        }
      }, 10000);
    },
  },
  created() {
    //定时器
    this.setTimer();
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.home {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    position: relative;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      z-index: 100;
    }
    p {
      cursor: pointer;
      color: white;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: rgba(0, 0, 0, 0.5);
    }
    .left {
      position: absolute;
      top: 50%;
      left: 0;
    }
    .right {
      position: absolute;
      top: 50%;
      right: 0;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 20px;
      top: 90%;
      .color {
        background: #173aa2;
        color: #173aa2;
      }
      li {
        cursor: pointer;
        width: 10px;
        height: 10px;
        background: rgba(58, 161, 219, 0.5);
		margin: 10px;
        border-radius: 50%;
      }
    }
  }
}
</style>