<template>
	<div>
		<div class="about" v-if="windowWidth>=1200">
			<div class="ab_div">
				<div style="z-index: 4;position: relative;">
					<div class="ab_title">
						<div class="ab_ti_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">ABOUT US</div>
						<div class="ab_ti_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" v-if="$i18n.locale=='cn'">关于芯航国际</div>
					</div>
					
					<!-- 公司简介 -->
					<div class="ab_introduction">
						<div class="ab_it_left">
							<div style="">
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
									{{$t('homeAbout.content1')}}
								</div>
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
									{{$t('homeAbout.content2')}}
								</div>
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" v-if="$i18n.locale=='en'">
									{{$t('homeAbout.content3')}}
								</div>
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" v-if="$i18n.locale=='en'">
									{{$t('homeAbout.content4')}}
								</div>
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" v-if="$i18n.locale=='en'">
									{{$t('homeAbout.content5')}}
								</div>
							</div>
									
							<!-- 公司行情信息 -->
							<div class="ab_it_le_marketQuotations">
								<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
									<div>
										<div style="display: flex;">
											<div class="text1">2014</div>
											<div class="text2">{{$t('homeAbout.marketQuotations.text1')}}</div>
										</div>
										<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content1')}}</div>
									</div>
								</div>
									
								<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0">
									<div>
										<div style="display: flex;">
											<div class="text1">10+</div>
											<div class="text2">{{$t('homeAbout.marketQuotations.text1')}}</div>
										</div>
										<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content2')}}</div>
									</div>
								</div>
									
								<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0">
									<div>
										<div style="display: flex;">
											<div class="text1">26</div>
											<div class="text2">{{$t('homeAbout.marketQuotations.text2')}}</div>
										</div>
										<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content3')}}</div>
									</div>
								</div>
									
								<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="0">
									<div>
										<div style="display: flex;">
											<div class="text1">100+</div>
											<div class="text2">{{$t('homeAbout.marketQuotations.text3')}}</div>
										</div>
										<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content4')}}</div>
									</div>
								</div>
							</div>
									
							<!-- <div class="learnMore">LEARN MORE
							</div> -->
							<div class="btn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" @click="rightClick(4)">
								<a class="learnMore"></a>
								<a class="learnMore2">LEARN MORE</a>
								<!-- <a class="learnMore2">LEARN MORE</a> -->
							</div>
							
							<!-- <div style="width: 100%;height: 900px;">1111111111111</div> -->
						</div>
						
						<!-- 右边内容 -->
						<div class="ab_it_right">
							<div style="display: flex;">
								<div class="fn_btn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" @click="rightClick(0)">
									<div style="z-index: 1;position: relative;">
										<el-image class="img" :src="require('@/assets/img/course.png')" fit="cover"></el-image>
										<div class="text">{{$t('homeAbout.right.text1')}}</div>
									</div>
									
									<div class="fn_btn_box">
										<div>
											<el-image class="img" :src="require('@/assets/img/courseHover.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text1')}}</div>
										</div>
									</div>
								</div>
								
								<div class="fn_btn wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0" style="margin-left: 20px;" @click="rightClick(1)">
									<div style="z-index: 1;position: relative;">
										<el-image class="img" :src="require('@/assets/img/honour.png')" fit="cover"></el-image>
										<div class="text">{{$t('homeAbout.right.text2')}}</div>
									</div>
									
									<div class="fn_btn_box">
										<div>
											<el-image class="img" :src="require('@/assets/img/honourHover.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text2')}}</div>
										</div>
									</div>
								</div>
							</div>
							
							<div style="display: flex;margin-top: 20px;">
								<div class="fn_btn wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0" style="" @click="rightClick(2)">
									<div style="z-index: 1;position: relative;">
										<el-image class="img" :src="require('@/assets/img/civilization.png')" fit="cover"></el-image>
										<div class="text">{{$t('homeAbout.right.text3')}}</div>
									</div>
									
									<div class="fn_btn_box">
										<div>
											<el-image class="img" :src="require('@/assets/img/civilizationHover.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text3')}}</div>
										</div>
									</div>
								</div>
								
								<div class="fn_btn wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="0" style="margin-left: 20px;" @click="rightClick(3)">
									<div style="z-index: 1;position: relative;">
										<el-image class="img" :src="require('@/assets/img/frame.png')" fit="cover"></el-image>
										<div class="text">{{$t('homeAbout.right.text4')}}</div>
									</div>
									
									<div class="fn_btn_box">
										<div>
											<el-image class="img" :src="require('@/assets/img/frameHover.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text4')}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="companyEnvironment">
						<div class="ce_div wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
							<el-image class="ce_div_image" :src="require('@/assets/img/companyProfile.png')" fit="cover"></el-image>
						</div>
						
						<div class="ce_div2 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout1.jpg')" :preview-src-list="srcList1" fit="cover"></el-image>
						</div>
						<div class="ce_div2 wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout2.jpg')" :preview-src-list="srcList2" fit="cover"></el-image>
						</div>
						<div class="ce_div2 wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="0">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout3.jpg')" :preview-src-list="srcList3" fit="cover"></el-image>
						</div>
						<div class="ce_div2 wow fadeInUp" data-wow-duration="1.8s" data-wow-delay="1.8s" data-wow-offset="0">
							<el-image class="ce_div_image2" :src="require('@/assets/img/adout4.jpg')" :preview-src-list="srcList4" fit="cover"></el-image>
						</div>
					</div>
					
					<div class="agentBrand">
						<!-- <div class="ab_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="100">Agency brand</div> -->
						<div class="ab_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="100">{{$t('homeAbout.title')}}</div>
						
						<div class=" wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="100">
							<el-carousel height="200px" :interval="6000">
							  <el-carousel-item v-for="(item,index) in partner" :key="index">
							    <div class="ab_carousel">
							    	<div class="ab_ca_div" v-for="(item2,index2) in item" :key="index2">
							    		<el-image class="ab_ca_div_image" :src="item2.brandImage" :fit="'cover'"></el-image>
							    	</div>
							    </div>
							  </el-carousel-item>
							</el-carousel>
						</div>
					</div>
				</div>
				
				<div class="imageDiv"
					:style="'height:' + (windowHeight + 900) + 'px;background-image:' + `url(${require('@/assets/img/Map1.png')})`">
				</div>
			</div>
		</div>
		
		<div class="about" v-if="windowWidth<=1199">
			<div class="ab_div">
				<div style="z-index: 4;position: relative;">
					<div class="ab_title">
						<div class="ab_ti_en wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">ABOUT US</div>
						<div class="ab_ti_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" v-if="$i18n.locale=='cn'">关于芯航国际</div>
					</div>
					
					<!-- 公司简介 -->
					<div class="ab_introduction">
						<div class="ab_it_left">
							<div style="">
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
									{{$t('homeAbout.content1')}}
								</div>
								<div class="content wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
									{{$t('homeAbout.content2')}}
								</div>
							</div>
									
							<!-- 公司行情信息 -->
							<div >
								<div class="ab_it_le_marketQuotations">
									<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0">
										<div>
											<div style="display: flex;">
												<div class="text1">2014</div>
												<div class="text2">{{$t('homeAbout.marketQuotations.text1')}}</div>
											</div>
											<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content1')}}</div>
										</div>
									</div>
										
									<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0">
										<div>
											<div style="display: flex;">
												<div class="text1">10+</div>
												<div class="text2">{{$t('homeAbout.marketQuotations.text1')}}</div>
											</div>
											<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content2')}}</div>
										</div>
									</div>
								</div>
								
								<div class="ab_it_le_marketQuotations">
									<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0">
										<div>
											<div style="display: flex;">
												<div class="text1">26</div>
												<div class="text2">{{$t('homeAbout.marketQuotations.text2')}}</div>
											</div>
											<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content3')}}</div>
										</div>
									</div>
										
									<div class="ab_it_le_mq_div wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="0">
										<div>
											<div style="display: flex;">
												<div class="text1">100+</div>
												<div class="text2">{{$t('homeAbout.marketQuotations.text3')}}</div>
											</div>
											<div class="text3" style="color: #666;">{{$t('homeAbout.marketQuotations.content4')}}</div>
										</div>
									</div>
								</div>
							</div>
							
							<div class="ab_it_right2">
								<div style="display: flex;align-items: center;justify-content: center;">
									<div class="fn_btn2 wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" @click="rightClick(0)">
										<div style="z-index: 1;position: relative;text-align: center;">
											<el-image class="img" :src="require('@/assets/img/course.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text1')}}</div>
										</div>
										
										<div class="fn_btn_box">
											<div>
												<el-image class="img" :src="require('@/assets/img/courseHover.png')" fit="cover"></el-image>
												<div class="text">{{$t('homeAbout.right.text1')}}</div>
											</div>
										</div>
									</div>
									
									<div class="fn_btn wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0" style="margin-left: 20px;" @click="rightClick(1)">
										<div style="z-index: 1;position: relative;text-align: center;">
											<el-image class="img" :src="require('@/assets/img/honour.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text2')}}</div>
										</div>
										
										<div class="fn_btn_box">
											<div>
												<el-image class="img" :src="require('@/assets/img/honourHover.png')" fit="cover"></el-image>
												<div class="text">{{$t('homeAbout.right.text2')}}</div>
											</div>
										</div>
									</div>
								</div>
								
								<div style="display: flex;margin-top: 20px;align-items: center;justify-content: center;">
									<div class="fn_btn2 wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0" style="" @click="rightClick(2)">
										<div style="z-index: 1;position: relative;text-align: center;">
											<el-image class="img" :src="require('@/assets/img/civilization.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text3')}}</div>
										</div>
										
										<div class="fn_btn_box">
											<div>
												<el-image class="img" :src="require('@/assets/img/civilizationHover.png')" fit="cover"></el-image>
												<div class="text">{{$t('homeAbout.right.text3')}}</div>
											</div>
										</div>
									</div>
									
									<div class="fn_btn wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="0" style="margin-left: 20px;" @click="rightClick(3)">
										<div style="z-index: 1;position: relative;text-align: center;">
											<el-image class="img" :src="require('@/assets/img/frame.png')" fit="cover"></el-image>
											<div class="text">{{$t('homeAbout.right.text4')}}</div>
										</div>
										
										<div class="fn_btn_box">
											<div>
												<el-image class="img" :src="require('@/assets/img/frameHover.png')" fit="cover"></el-image>
												<div class="text">{{$t('homeAbout.right.text4')}}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
									
							<div class="btn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="0" @click="rightClick(4)">
								<a class="learnMore"></a>
								<a class="learnMore2">LEARN MORE</a>
							</div>
							
							<div class="companyEnvironment2">
								<div class="ce_div3 wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="0">
									<el-image class="ce_div_image2" :src="require('@/assets/img/companyProfile.png')" :preview-src-list="srcList1" fit="cover"></el-image>
								</div>
								<div class="ce_div3 wow fadeInUp" data-wow-duration="1.4s" data-wow-delay="1.4s" data-wow-offset="0">
									<el-image class="ce_div_image2" :src="require('@/assets/img/adout2.jpg')" :preview-src-list="srcList2" fit="cover"></el-image>
								</div>
							</div>
							<div class="companyEnvironment2">
								<div class="ce_div3 wow fadeInUp" data-wow-duration="1.6s" data-wow-delay="1.6s" data-wow-offset="0">
									<el-image class="ce_div_image2" :src="require('@/assets/img/adout3.jpg')" :preview-src-list="srcList3" fit="cover"></el-image>
								</div>
								<div class="ce_div3 wow fadeInUp" data-wow-duration="1.8s" data-wow-delay="1.8s" data-wow-offset="0">
									<el-image class="ce_div_image2" :src="require('@/assets/img/adout4.jpg')" :preview-src-list="srcList4" fit="cover"></el-image>
								</div>
							</div>
							
							<div class="agentBrand">
								<div class="ab_cn wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s" data-wow-offset="100">{{$t('homeAbout.title')}}</div>
								
								<div class=" wow fadeInUp" data-wow-duration="1.2s" data-wow-delay="1.2s" data-wow-offset="100">
									<el-carousel height="100px" :interval="6000">
									  <el-carousel-item v-for="(item,index) in partner" :key="index">
									    <div class="ab_carousel2">
									    	<div class="ab_ca_div2" v-for="(item2,index2) in item" :key="index2">
									    		<el-image class="ab_ca_div_image2" :src="item2.brandImage" :fit="'cover'"></el-image>
									    	</div>
									    </div>
									  </el-carousel-item>
									</el-carousel>
								</div>
							</div>
							
						</div>
					</div>
					
				</div>
				
				<div class="imageDiv"
					:style="'height:' + (windowHeight + 900) + 'px;background-image:' + `url(${require('@/assets/img/Map1.png')})`">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import WOW from "wowjs";
	import {
		brandList
	} from '@/api/home/index.js';
	import { agentInfo } from "./data.js" 
	export default {
		props: {
			//宽
			windowWidth: {
				type: Number,
				default: 0
			},
			//高
			windowHeight: {
				type: Number,
				default: 0
			},
		},
		data() {
			return{
				srcList1:[require('@/assets/img/adout1.jpg')],
				srcList2:[require('@/assets/img/adout2.jpg')],
				srcList3:[require('@/assets/img/adout3.jpg')],
				srcList4:[require('@/assets/img/adout4.jpg')],
				//合作伙伴列表
				partner:[],
			}
		},
		
		created() {
			
		},
		
		watch:{
			windowWidth(val){
				this.listData();
			}
		},
		
		mounted() {
			let wow = new WOW.WOW({ boxClass: 'wow', animateClass: 'animated', offset:0, mobile: true, live: true }); wow.init();
			this.listData();
		},
		
		computed:{
			
		},
		
		methods:{
			//-------------品牌列表接口-----------------
			listData:function(){
				var a = agentInfo()
				console.log(a)
				for(var i = 0; i < a.length; i += 5){
					this.partner.push(a.slice(i,i+5))
				}
			},
			
			//-------------------------右边点击状态----------------------------------
			rightClick:function(index){
				switch (index) {
					case 0:
						this.$router.push({
							path: '/aboutUs',
							query:{
								selector:'#developmentHistory'
							}
						})
						break;
					case 1:
						this.$router.push({
							path: '/aboutUs',
							query:{
								selector:'#qualificationsHonors'
							}
						})
						break;
					case 2:
						this.$router.push({
							path: '/aboutUs',
							query:{
								selector:'#coreConcepts'
							}
						})
						break;
					case 3:
						
						break;
					case 4:
						this.$router.push({
							path: '/aboutUs',
						})
						break;
					default:
						break;
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@font-face {
		font-family: 'BigJohn';
		src: url('../../../assets/big-john-woff2-2.ttf') format('truetype');
	}
	
	@font-face {
		font-family: 'zhenyan';
		src: url('../../../assets/RuiZiZhenYanTiMianFeiShangYong-2.ttf') format('truetype');
	}
	
	@media only screen and (min-width:1920px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.about{
			position: relative;
		}
		
		.ab_div{
			padding: 120px 200px 60px 200px;
			position: relative;
		}
		
		//公司简介标题
		.ab_title{
			.ab_ti_en{
				display: inline-block;
				font-size: 48px;
				line-height: 1.4;
				font-family: BigJohn;
				text-transform: uppercase;
				color: transparent;
				background: linear-gradient(to right,#621ed2,#3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_ti_cn{
				font-size: 30px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		//公司简介
		.ab_introduction{
			width: 100%;
			display: flex;
			
			.ab_it_left{
				width: 60%;
				padding-top: 40px;
				
				.content{
					color: #333;
					font-size: 16px;
					line-height: 2;
					text-indent:33px;
				}
			}
			
			.ab_it_right{
				width: 40%;
				padding-left: 80px;
			}
		}
		
		//公司行情
		.ab_it_le_marketQuotations{
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding-top: 50px;
			
			.ab_it_le_mq_div{
				width: 25%;
				justify-content: center;
				align-items: center;
				display: flex;
				
				.text1{
					font-size: 35px;
					font-weight: bold;
					font-family: BigJohn;
					background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				
				.text2{
					display: flex;
					align-items: flex-end;
					padding-bottom: 10px;
					padding-left: 5px;
					font-weight: bold;
					font-size: 16px;
					color: #666;
				}
				
				.text3{
					padding-right: 10px;
					height: 60px;
					font-size: 12px;
				}
			}
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 50%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
		
		.btn {
			margin-top: 40px;
			width: 190px;
			line-height: 52px;
			height: 50px;
			border: 1px solid #333;
			color: #333;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.fn_btn{
			position: relative;
			padding: 30px 15px;
			border-radius: 5px;
			background: #e4e4e4;
			width: 200px;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			margin-left: 80px;
		}
		
		.fn_btn .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn .text{
			font-size: 18px;
			color: #333;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn .fn_btn_box{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 5px;
			background: linear-gradient(to right, #621ed2, #3066f5);
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transform: scale(0);
		}
		
		.fn_btn .fn_btn_box .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn .fn_btn_box .text{
			font-size: 18px;
			color: #fff;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn:hover .fn_btn_box{
			width: 100%;
			transition: all 0.5s;
			z-index: 2;
			opacity: 1;
			transform: scale(1);
		}
		
		.agentBrand{
			text-align: center;
			padding-top: 80px;
			
			.ab_en{
				display:inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family:BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_cn{
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
			
			.ab_carousel{
				padding: 20px;
				height: 200px;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.ab_ca_div{
					width: 16%;
					margin: 10px 20px;
					display: flex;
					height: 100%;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					border: 1px solid #ccc;
					
					.ab_ca_div_image{
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
					
					.ab_ca_div_image:hover{
						text-align: center;
						position: relative;
						transform: scale(1.3,1.3);
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.about{
			position: relative;
		}
		
		.ab_div{
			padding: 120px 200px 60px 200px;
			position: relative;
		}
		
		//公司简介标题
		.ab_title{
			.ab_ti_en{
				display: inline-block;
				font-size: 48px;
				line-height: 1.4;
				font-family: BigJohn;
				text-transform: uppercase;
				color: transparent;
				background: linear-gradient(to right,#621ed2,#3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_ti_cn{
				font-size: 30px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		//公司简介
		.ab_introduction{
			width: 100%;
			display: flex;
			
			.ab_it_left{
				width: 60%;
				padding-top: 40px;
				
				.content{
					color: #333;
					font-size: 16px;
					line-height: 2;
					text-indent:33px;
				}
			}
			
			.ab_it_right{
				width: 40%;
				padding-left: 80px;
			}
		}
		
		//公司行情
		.ab_it_le_marketQuotations{
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding-top: 50px;
			
			.ab_it_le_mq_div{
				width: 25%;
				justify-content: center;
				align-items: center;
				display: flex;
				
				.text1{
					font-size: 35px;
					font-weight: bold;
					font-family: BigJohn;
					background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				
				.text2{
					display: flex;
					align-items: flex-end;
					padding-bottom: 10px;
					padding-left: 5px;
					font-weight: bold;
					font-size: 16px;
					color: #666;
				}
				
				.text3{
					padding-right: 10px;
					height: 60px;
					font-size: 12px;
				}
			}
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 50%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
		
		.btn {
			margin-top: 40px;
			width: 190px;
			line-height: 52px;
			height: 50px;
			border: 1px solid #333;
			color: #333;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.fn_btn{
			position: relative;
			padding: 30px 15px;
			border-radius: 5px;
			background: #e4e4e4;
			width: 200px;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			margin-left: 80px;
		}
		
		.fn_btn .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn .text{
			font-size: 18px;
			color: #333;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn .fn_btn_box{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 5px;
			background: linear-gradient(to right, #621ed2, #3066f5);
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transform: scale(0);
		}
		
		.fn_btn .fn_btn_box .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn .fn_btn_box .text{
			font-size: 18px;
			color: #fff;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn:hover .fn_btn_box{
			width: 100%;
			transition: all 0.5s;
			z-index: 2;
			opacity: 1;
			transform: scale(1);
		}
		
		.agentBrand{
			text-align: center;
			padding-top: 80px;
			
			.ab_en{
				display:inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family:BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_cn{
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
			
			.ab_carousel{
				padding: 20px;
				height: 200px;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.ab_ca_div{
					width: 16%;
					margin: 10px 20px;
					display: flex;
					height: 100%;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					border: 1px solid #ccc;
					
					.ab_ca_div_image{
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
					
					.ab_ca_div_image:hover{
						text-align: center;
						position: relative;
						transform: scale(1.3,1.3);
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.about{
			position: relative;
		}
		
		.ab_div{
			padding: 120px 100px 60px 100px;
			position: relative;
		}
		
		//公司简介标题
		.ab_title{
			.ab_ti_en{
				display: inline-block;
				font-size: 48px;
				line-height: 1.4;
				font-family: BigJohn;
				text-transform: uppercase;
				color: transparent;
				background: linear-gradient(to right,#621ed2,#3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_ti_cn{
				font-size: 30px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		//公司简介
		.ab_introduction{
			width: 100%;
			display: flex;
			
			.ab_it_left{
				width: 60%;
				padding-top: 40px;
				
				.content{
					color: #333;
					font-size: 16px;
					line-height: 2;
					text-indent:33px;
				}
			}
			
			.ab_it_right{
				width: 40%;
				// padding-left: 80px;
			}
		}
		
		//公司行情
		.ab_it_le_marketQuotations{
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding-top: 50px;
			
			.ab_it_le_mq_div{
				width: 25%;
				justify-content: center;
				align-items: center;
				display: flex;
				
				.text1{
					font-size: 52px;
					font-weight: bold;
					font-family: BigJohn;
					background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				
				.text2{
					display: flex;
					align-items: flex-end;
					padding-bottom: 10px;
					padding-left: 5px;
					font-weight: bold;
					font-size: 16px;
					color: #666;
				}
				
				.text3{
					height: 60px;
					padding-right: 10px;
					font-size: 12px;
				}
			}
		}
		
		//公司简介相册
		.companyEnvironment{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div2{
				width: 21.25%;
				
				.ce_div_image2{
					width: 100%;
					height: 180px;
					padding-left: 20px;
				}
			}
		}
		
		.btn {
			margin-top: 40px;
			width: 190px;
			line-height: 52px;
			height: 50px;
			border: 1px solid #333;
			color: #333;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.fn_btn{
			position: relative;
			padding: 30px 15px;
			border-radius: 5px;
			background: #e4e4e4;
			width: 200px;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			// margin-left: 80px;
		}
		
		.fn_btn .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn .text{
			font-size: 18px;
			color: #333;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn .fn_btn_box{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 5px;
			background: linear-gradient(to right, #621ed2, #3066f5);
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transform: scale(0);
		}
		
		.fn_btn .fn_btn_box .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn .fn_btn_box .text{
			font-size: 18px;
			color: #fff;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn:hover .fn_btn_box{
			width: 100%;
			transition: all 0.5s;
			z-index: 2;
			opacity: 1;
			transform: scale(1);
		}
		
		.agentBrand{
			text-align: center;
			padding-top: 80px;
			
			.ab_en{
				display:inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family:BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_cn{
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
			
			.ab_carousel{
				padding-top: 20px;
				height: 200px;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.ab_ca_div{
					width: 16%;
					margin: 10px 20px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					border: 1px solid #ccc;
					
					.ab_ca_div_image{
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
					
					.ab_ca_div_image:hover{
						text-align: center;
						position: relative;
						transform: scale(1.3,1.3);
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		
			.imageDiv {
				position: absolute;
				width: 100%;
				top: 0;
				left: 0;
				// width: 100%;
				// overflow: hidden;
				// overflow-y: scroll;
				background-repeat: no-repeat;
				background-attachment: fixed;
				z-index: 1;
				// background-position: left center;
				// background-size: 100% auto;
				// background-color: #fff;
				// background-image: url('@/assets/img/deba.png');
				// background-attachment: scroll;
				// animation: myfirst 2s;
				// -moz-animation: myfirst 2s;
				// /* Firefox */
				// -webkit-animation: myfirst 2s;
				// /* Safari 和 Chrome */
				// -o-animation: myfirst 2s;
				/* Opera */
			}
			
			@keyframes myfirst {
				0% {
					opacity: 0;
				}
			
				50% {
					opacity: 0;
				}
			
				100% {
					opacity: 1;
				}
			}
			
			.about{
				position: relative;
			}
			
			.ab_div{
				padding: 120px 60px 60px 60px;
				position: relative;
			}
			
			//公司简介标题
			.ab_title{
				.ab_ti_en{
					display: inline-block;
					font-size: 48px;
					line-height: 1.4;
					font-family: BigJohn;
					text-transform: uppercase;
					color: transparent;
					background: linear-gradient(to right,#621ed2,#3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ab_ti_cn{
					font-size: 30px;
					color: #333;
					font-family: zhenyan;
				}
			}
			
			//公司简介
			.ab_introduction{
				width: 100%;
				display: flex;
				
				.ab_it_left{
					width: 60%;
					padding-top: 40px;
					
					.content{
						color: #333;
						font-size: 16px;
						line-height: 2;
						text-indent:33px;
					}
				}
				
				.ab_it_right{
					width: 40%;
					// padding-left: 80px;
				}
			}
			
			//公司行情
			.ab_it_le_marketQuotations{
				width: 100%;
				position: relative;
				display: flex;
				align-items: center;
				padding-top: 50px;
				
				.ab_it_le_mq_div{
					width: 25%;
					justify-content: center;
					align-items: center;
					display: flex;
					
					.text1{
						font-size: 52px;
						font-weight: bold;
						font-family: BigJohn;
						background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
					}
					
					.text2{
						display: flex;
						align-items: flex-end;
						padding-bottom: 10px;
						padding-left: 5px;
						font-weight: bold;
						font-size: 16px;
						color: #666;
					}
					
					.text3{
						height: 60px;
						padding-right: 10px;
						font-size: 12px;
					}
				}
			}
			
			//公司简介相册
			.companyEnvironment{
				padding-top: 40px;
				width: 100%;
				display: flex;
				
				.ce_div{
					width: 15%;
					
					.ce_div_image{
						width: 100%;
						height: 180px;
					}
				}
				
				.ce_div2{
					width: 21.25%;
					
					.ce_div_image2{
						width: 100%;
						height: 180px;
						padding-left: 20px;
					}
				}
			}
			
			.btn {
				margin-top: 40px;
				width: 190px;
				line-height: 52px;
				height: 50px;
				border: 1px solid #333;
				color: #333;
				font-family: BigJohn;
				font-weight: bold;
				text-align: center;
				border-radius: 5px;
				user-select: none;
				cursor: pointer;
				position: relative;
				font-size: 16px;
				overflow: hidden;
			}
			
			.btn .learnMore {
				width: 100%;
				height: 100%;
				background: linear-gradient(to right, #621ed2, #3066f5);
				// background-repeat: no-repeat;
				left: -100%;
				position: absolute;
				transition: 0.6s;
			}
			
			.btn:hover .learnMore {
				left: 0%;
				color: #fff;
				// z-index: 4;
			}
			
			.btn .learnMore2 {
				position: relative;
				transition: 0.6s;
			}
			
			.btn:hover {
				border: 1px solid #fff;
				transition: 0.6s;
			}
			
			.btn:hover .learnMore2 {
				color: #fff;
				// z-index: 3;
			}
			
			.btn:active {
				box-shadow: 0 0 3px #ccc;
			}
			
			.fn_btn{
				position: relative;
				padding: 30px 15px;
				border-radius: 5px;
				background: #e4e4e4;
				width: 200px;
				height: 200px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				// margin-left: 80px;
			}
			
			.fn_btn .img{
				width: 60px;
				height: 48px;
			}
			
			.fn_btn .text{
				font-size: 18px;
				color: #333;
				text-align: center;
				margin-top: 12px;
			}
			
			.fn_btn .fn_btn_box{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				border-radius: 5px;
				background: linear-gradient(to right, #621ed2, #3066f5);
				opacity: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				transform: scale(0);
			}
			
			.fn_btn .fn_btn_box .img{
				width: 60px;
				height: 48px;
			}
			
			.fn_btn .fn_btn_box .text{
				font-size: 18px;
				color: #fff;
				text-align: center;
				margin-top: 12px;
			}
			
			.fn_btn:hover .fn_btn_box{
				width: 100%;
				transition: all 0.5s;
				z-index: 2;
				opacity: 1;
				transform: scale(1);
			}
			
			.agentBrand{
				text-align: center;
				padding-top: 80px;
				
				.ab_en{
					display:inline-block;
					font-size: 62px;
					line-height: 1.4;
					color: transparent;
					font-family:BigJohn;
					text-transform: uppercase;
					background: linear-gradient(to right, #621ed2, #3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					text-fill-color: transparent;
					font-weight: bold;
				}
				
				.ab_cn{
					font-size: 40px;
					color: #333;
					font-family: zhenyan;
				}
				
				.ab_carousel{
					padding-top: 20px;
					height: 200px;
					display: flex;
					justify-content: center;
					align-items: center;
					
					.ab_ca_div{
						width: 16%;
						margin: 10px 20px;
						height: 100%;
						display: flex;
						justify-content: center;
						align-items: center;
						overflow: hidden;
						border: 1px solid #ccc;
						
						.ab_ca_div_image{
							text-align: center;
							position: relative;
							width: 100%;
							transition: all 0.5s;
						}
						
						.ab_ca_div_image:hover{
							text-align: center;
							position: relative;
							transform: scale(1.3,1.3);
						}
					}
				}
			}
		
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.about{
			position: relative;
		}
		
		.ab_div{
			padding: 40px;
			position: relative;
		}
		
		//公司简介标题
		.ab_title{
			.ab_ti_en{
				display: inline-block;
				font-size: 48px;
				line-height: 1.4;
				font-family: BigJohn;
				text-transform: uppercase;
				color: transparent;
				background: linear-gradient(to right,#621ed2,#3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_ti_cn{
				font-size: 30px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		//公司简介
		.ab_introduction{
			width: 100%;
			display: flex;
			
			.ab_it_left{
				width: 100%;
				padding-top: 20px;
				
				.content{
					color: #333;
					font-size: 14px;
					line-height: 2;
					text-indent:33px;
				}
			}
			
			.ab_it_right2{
				width: 100%;
				// padding-left: 80px;
			}
		}
		
		//公司行情
		.ab_it_le_marketQuotations{
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding-top: 40px;
			
			.ab_it_le_mq_div{
				width: 50%;
				justify-content: left;
				align-items: center;
				display: flex;
				
				.text1{
					font-size: 40px;
					font-weight: bold;
					font-family: BigJohn;
					background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				
				.text2{
					display: flex;
					align-items: flex-end;
					padding-bottom: 10px;
					padding-left: 5px;
					font-weight: bold;
					font-size: 16px;
					color: #666;
				}
				
				.text3{
					height: 50px;
					padding-right: 10px;
					font-size: 12px;
				}
			}
		}
		
		//公司简介相册
		.companyEnvironment2{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			
			.ce_div3{
				width: 50%;
				
				.ce_div_image2{
					width: 100%;
					height: 250px;
					padding-left: 20px;
				}
			}
		}
		
		.btn {
			margin-top: 40px;
			width: 190px;
			line-height: 52px;
			height: 50px;
			border: 1px solid #333;
			color: #333;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.fn_btn2{
			position: relative;
			padding: 30px 15px;
			border-radius: 5px;
			background: #e4e4e4;
			width: 200px;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		
		.fn_btn2 .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn2 .text{
			font-size: 18px;
			color: #333;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn2 .fn_btn_box{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 5px;
			background: linear-gradient(to right, #621ed2, #3066f5);
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transform: scale(0);
		}
		
		.fn_btn2 .fn_btn_box .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn2 .fn_btn_box .text{
			font-size: 18px;
			color: #fff;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn2:hover .fn_btn_box{
			width: 100%;
			transition: all 0.5s;
			z-index: 2;
			opacity: 1;
			transform: scale(1);
		}
		
		.agentBrand{
			text-align: center;
			padding-top: 80px;
			
			.ab_en{
				display:inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family:BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_cn{
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
			
			.ab_carousel2{
				padding-top: 20px;
				height: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.ab_ca_div2{
					width: 16%;
					margin: 10px 20px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					border: 1px solid #ccc;
					
					.ab_ca_div_image2{
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
					
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.imageDiv {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			// width: 100%;
			// overflow: hidden;
			// overflow-y: scroll;
			background-repeat: no-repeat;
			background-attachment: fixed;
			z-index: 1;
			// background-position: left center;
			// background-size: 100% auto;
			// background-color: #fff;
			// background-image: url('@/assets/img/deba.png');
			// background-attachment: scroll;
			// animation: myfirst 2s;
			// -moz-animation: myfirst 2s;
			// /* Firefox */
			// -webkit-animation: myfirst 2s;
			// /* Safari 和 Chrome */
			// -o-animation: myfirst 2s;
			/* Opera */
		}
		
		@keyframes myfirst {
			0% {
				opacity: 0;
			}
		
			50% {
				opacity: 0;
			}
		
			100% {
				opacity: 1;
			}
		}
		
		.about{
			position: relative;
		}
		
		.ab_div{
			padding: 40px;
			position: relative;
		}
		
		//公司简介标题
		.ab_title{
			.ab_ti_en{
				display: inline-block;
				font-size: 48px;
				line-height: 1.4;
				font-family: BigJohn;
				text-transform: uppercase;
				color: transparent;
				background: linear-gradient(to right,#621ed2,#3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_ti_cn{
				font-size: 30px;
				color: #333;
				font-family: zhenyan;
			}
		}
		
		//公司简介
		.ab_introduction{
			width: 100%;
			display: flex;
			
			.ab_it_left{
				width: 100%;
				padding-top: 20px;
				
				.content{
					color: #333;
					font-size: 14px;
					line-height: 2;
					text-indent:33px;
				}
			}
			
			.ab_it_right2{
				width: 100%;
				// padding-left: 80px;
			}
		}
		
		//公司行情
		.ab_it_le_marketQuotations{
			width: 100%;
			position: relative;
			display: flex;
			align-items: center;
			padding-top: 40px;
			
			.ab_it_le_mq_div{
				width: 50%;
				justify-content: left;
				align-items: center;
				display: flex;
				
				.text1{
					font-size: 40px;
					font-weight: bold;
					font-family: BigJohn;
					background-image: -webkit-linear-gradient(left,#621ed2,#3066f5);
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
				
				.text2{
					display: flex;
					align-items: flex-end;
					padding-bottom: 10px;
					padding-left: 5px;
					font-weight: bold;
					font-size: 16px;
					color: #666;
				}
				
				.text3{
					height: 50px;
					padding-right: 10px;
					font-size: 12px;
				}
			}
		}
		
		//公司简介相册
		.companyEnvironment2{
			padding-top: 40px;
			width: 100%;
			display: flex;
			
			.ce_div{
				width: 15%;
				
				.ce_div_image{
					width: 100%;
					height: 180px;
				}
			}
			
			.ce_div3{
				width: 50%;
				
				.ce_div_image2{
					width: 100%;
					height: 120px;
					padding-left: 20px;
				}
			}
		}
		
		.btn {
			margin-top: 40px;
			width: 190px;
			line-height: 52px;
			height: 50px;
			border: 1px solid #333;
			color: #333;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.fn_btn2{
			position: relative;
			padding: 30px 15px;
			border-radius: 5px;
			background: #e4e4e4;
			width: 200px;
			height: 200px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
		}
		
		.fn_btn2 .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn2 .text{
			font-size: 18px;
			color: #333;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn2 .fn_btn_box{
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-radius: 5px;
			background: linear-gradient(to right, #621ed2, #3066f5);
			opacity: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			transform: scale(0);
		}
		
		.fn_btn2 .fn_btn_box .img{
			width: 60px;
			height: 48px;
		}
		
		.fn_btn2 .fn_btn_box .text{
			font-size: 18px;
			color: #fff;
			text-align: center;
			margin-top: 12px;
		}
		
		.fn_btn2:hover .fn_btn_box{
			width: 100%;
			transition: all 0.5s;
			z-index: 2;
			opacity: 1;
			transform: scale(1);
		}
		
		.agentBrand{
			text-align: center;
			padding-top: 80px;
			
			.ab_en{
				display:inline-block;
				font-size: 62px;
				line-height: 1.4;
				color: transparent;
				font-family:BigJohn;
				text-transform: uppercase;
				background: linear-gradient(to right, #621ed2, #3066f5);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				text-fill-color: transparent;
				font-weight: bold;
			}
			
			.ab_cn{
				font-size: 40px;
				color: #333;
				font-family: zhenyan;
			}
			
			.ab_carousel2{
				padding-top: 20px;
				height: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				
				.ab_ca_div2{
					width: 25%;
					margin: 10px 6px;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					overflow: hidden;
					border: 1px solid #ccc;
					
					.ab_ca_div_image2{
						text-align: center;
						position: relative;
						width: 100%;
						transition: all 0.5s;
					}
					
				}
			}
		}
	}
</style>